import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useDaylightSummaryStore from "../../summary/store/DaylightSummaryStore.ts"

const DailyDaylightProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentDaylightResponse = useDaylightSummaryStore((state) => state.setCurrentDaylightResponse)
  const currentOffsetDays = useDaylightSummaryStore((state) => state.currentOffsetDays)
  const currentLimitDays = useDaylightSummaryStore((state) => state.currentLimitDays)

  const { clientId } = useParams()

  const {
    data: daylightDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["dailyDaylightData", clientId, currentOffsetDays, currentLimitDays],
    retry: false,
    queryFn: async () => {
      // Adjust this endpoint to match your actual daylight data resource
      const endpoint = `insight/summary/daylight-time/${clientId}?offset_days=${currentOffsetDays}&limit_days=${currentLimitDays}`
      const { data, status } = await api.get<DaylightSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching daylight data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && daylightDataResponse) {
      setCurrentDaylightResponse(daylightDataResponse)
    }
  }, [isSuccess, daylightDataResponse, setCurrentDaylightResponse])

  if (isLoading) {
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load daylight data at this time</div>
    )
  }

  return <>{children}</>
}

export default DailyDaylightProvider
