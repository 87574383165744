import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode } from "react"
import { NavLink } from "react-router-dom"

import NavBarButton from "@components/Buttons/NavBarButton"

interface NavigationButtonProps {
  to?: string
  href?: string
  icon?: IconProp
  label?: string
}
const NavigationItem = ({ to, href, icon, label }: NavigationButtonProps) => {
  const baseClasses =
    "text-neutral-300 px-1 rounded-full hover:text-white hover:bg-black transition-colors duration-200 ease-in-out flex flex-col align-middle text-center"

  const activeClasses = baseClasses + " " + "font-bold text-neutral-950"
  const pendingClasses = baseClasses + " " + "text-neutral-600"
  const hideText = label == "Invite Users" || label == "Settings"
  label = hideText ? "" : label

  const LinkComponent = ({ children, ...props }: { children: ReactNode }) => {
    if (href)
      return (
        <a href={href} className={baseClasses} target={"_blank"} rel={"noopener noreferrer"} {...props}>
          {children}
        </a>
      )
    if (to)
      return (
        <NavLink
          to={to}
          className={({ isActive, isPending }) => (isPending ? pendingClasses : isActive ? activeClasses : baseClasses)}
        >
          {children}
        </NavLink>
      )
    return null
  }

  return (
    <LinkComponent>
      <NavBarButton>
        <li className={"flex flex-row align-middle text-center text-sm"}>
          {icon && <FontAwesomeIcon className="p-2" size={"lg"} icon={icon} />}
          <div className={`font-thin pt-2 ${label ? "pr-2" : ""}`}>{label}</div>
        </li>
      </NavBarButton>
    </LinkComponent>
  )
}

export default NavigationItem
