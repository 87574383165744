import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export interface BouncingDotsProps {
  delays?: string[]
}

const BouncingDots: React.FC<BouncingDotsProps> = ({ delays = ["0s", "0.2s", "0.4s"] }) => {
  return (
    <div className="flex space-x-1 text-xs">
      {delays.map((delay, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faCircle}
          size="2xs"
          className="animate-bounce"
          style={{ animationDelay: delay }}
        />
      ))}
    </div>
  )
}

export default BouncingDots
