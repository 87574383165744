import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useExerciseSummaryStore from "../../summary/store/ExerciseSummaryStore.ts"

const DailyExerciseActivityProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentExerciseData = useExerciseSummaryStore((state) => state.setCurrentExerciseResponse)
  const currentOffsetDays = useExerciseSummaryStore((state) => state.currentOffsetDays)
  const currentLimitDays = useExerciseSummaryStore((state) => state.currentLimitDays)

  const { clientId } = useParams()

  const {
    data: exerciseDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["dailyExerciseActivityData", clientId, currentOffsetDays, currentLimitDays],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/summary/exercise/${clientId}?offset_days=${currentOffsetDays}&limit_days=${currentLimitDays}`
      const { data, status } = await api.get<ExerciseSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching exercise data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && exerciseDataResponse) {
      setCurrentExerciseData(exerciseDataResponse)
    }
  }, [isSuccess, exerciseDataResponse, setCurrentExerciseData])

  if (isLoading) {
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="my-6 w-full text-center text-sm text-neutral-200">
        Could not load exercise activity at this time
      </div>
    )
  }

  return <>{children}</>
}

export default DailyExerciseActivityProvider
