import ConversationList from "./ConversationList"
import RelationshipSection from "./RelationshipSection"
import TranscriptComments from "./TranscriptComments"
import TranscriptHeader from "./TranscriptHeader"
import TranscriptSummary from "./TranscriptSummary"
import RelationshipsGraph from "./graph/RelationshipsGraph"
import TranscriptMood from "./graph/TranscriptMood"
import RelationshipGraphProvider from "./provider/RelationshipGraphProvider"
import TranscriptCommentsProvider from "./provider/TranscriptCommentsProvider"
import TranscriptMoodProvider from "./provider/TranscriptMoodProvider"
import useTranscriptDetailStore from "./store/TranscriptDetailStore"

const TranscriptDetailRenderer: React.FC = () => {
  const currentTranscriptDetail = useTranscriptDetailStore((state) => state.currentTranscriptDetail)

  if (!currentTranscriptDetail) {
    return <div>No transcript available.</div>
  }

  const { created_at, title, summary, conversation } = currentTranscriptDetail

  return (
    <div className="space-y-8">
      <TranscriptHeader title={title} createdAt={created_at} />
      <RelationshipGraphProvider>
        <RelationshipsGraph />
      </RelationshipGraphProvider>
      <RelationshipSection />
      <TranscriptMoodProvider>
        <TranscriptMood />
      </TranscriptMoodProvider>

      <TranscriptCommentsProvider>
        <TranscriptComments />
      </TranscriptCommentsProvider>
      <TranscriptSummary summary={summary} />
      <ConversationList conversation={conversation} />
    </div>
  )
}

export default TranscriptDetailRenderer
