export interface ConversationEntry {
  start_time: number
  end_time: number
  role: string
  text: string
  session_result_id?: string | undefined
}

interface ConversationMessageProps {
  msg: ConversationEntry
}

const ConversationMessage: React.FC<ConversationMessageProps> = ({ msg }) => {
  const startTimeText = new Date(msg.start_time * 1000).toISOString().slice(14, 19)
  const endTimeText = new Date(msg.end_time * 1000).toISOString().slice(14, 19)

  if (msg.role === "clinician") {
    return (
      <div className="flex flex-col items-end w-3/4 ml-auto">
        <div className="flex items-center relative">
          <div className="bg-white p-4 rounded-lg flex-1 border shadow-md relative" id={msg.session_result_id}>
            {msg.text}
            <span className="absolute top-0 right-6 transform translate-x-1/2 -translate-y-full text-neutral-300 text-xs font-semibold px-2 py-0.5 rounded-full">
              Clinician
            </span>
          </div>
        </div>
        <div className="text-xs text-neutral-300 text-right pt-1 pr-1 select-none">
          {startTimeText} - {endTimeText}
        </div>
      </div>
    )
  } else if (msg.role === "client") {
    return (
      <div className="flex flex-col items-start w-3/4">
        <div className="flex items-center">
          <div className="w-3 overflow-hidden">
            <div className="h-4 bg-neutral-100 rotate-45 transform origin-bottom-right rounded-sm"></div>
          </div>
          <div className="bg-neutral-100 p-4 rounded-lg flex-1" id={msg.session_result_id}>
            {msg.text}
          </div>
        </div>
        <div className="text-xs text-neutral-300 text-left pt-1 pl-4 select-none">
          {startTimeText} - {endTimeText}
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex flex-col items-start w-3/4">
        <div className="flex items-center">
          <div className="w-3 overflow-hidden">
            <div className="h-4 bg-neutral-200 rotate-45 transform origin-bottom-right rounded-sm"></div>
          </div>
          <div className="bg-neutral-200 p-4 rounded-lg flex-1" id={msg.session_result_id}>
            {msg.text}
          </div>
        </div>
        <div className="text-xs text-neutral-300 text-left pt-1 pl-4 select-none">
          {startTimeText} - {endTimeText}
        </div>
      </div>
    )
  }
}

export default ConversationMessage
