import { create } from "zustand"

interface ActiveCaloriesSummaryStore {
  currentActiveCaloriesResponse: ActiveCaloriesSummaryResponse | null
  setCurrentActiveCaloriesResponse: (response: ActiveCaloriesSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
  resetDateRange: () => void
}

const useActiveCaloriesSummaryStore = create<ActiveCaloriesSummaryStore>((set) => ({
  currentActiveCaloriesResponse: null,
  setCurrentActiveCaloriesResponse: (currentActiveCaloriesResponse) => set({ currentActiveCaloriesResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
  resetDateRange: () => set({ currentOffsetDays: 0, currentLimitDays: 30 }),
}))

export default useActiveCaloriesSummaryStore
