import * as d3 from "d3"

class ChoicesHistoryD3 {
  constructor(element, width, height, data) {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 10, right: 0, bottom: 30, left: 0 }
    this.svg = null

    this.createChart()
  }

  createChart() {
    d3.select(this.element).select("svg").remove()

    if (!this.data || !this.data.responses || this.data.responses.length === 0) {
      return
    }

    // Determine the longest choice and adjust left margin
    const testSvg = d3.select(this.element).append("svg").attr("class", "hidden-svg").style("visibility", "hidden")
    const textElement = testSvg.append("text").attr("class", "hidden-text")

    const maxChoiceLength = Math.max(
      ...this.data.choices.map((choice) => {
        textElement.text(choice)
        return textElement.node().getComputedTextLength()
      }),
    )

    testSvg.remove()
    this.margin.left = Math.min(300, Math.max(20, maxChoiceLength + 10))

    const svg = d3.select(this.element).append("svg").attr("width", this.width).attr("height", this.height)

    const chartGroup = svg.append("g").attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    // Parse dates and prepare data
    const parseDate = d3.timeParse("%Y-%m-%d")
    const responses = this.data.responses.map((d) => ({
      date: parseDate(d.date),
      values: d.values,
    }))

    // Flatten the responses for multiple values
    const flattenedData = responses.flatMap((response) =>
      response.values.map((value) => ({
        date: response.date,
        choice: value,
      })),
    )

    // Compute all unique dates (including those with empty values)
    const allDates = Array.from(new Set(responses.map((d) => d.date.getTime())))
      .map((t) => new Date(t))
      .sort((a, b) => a - b)

    // Compute responded dates (dates with at least one value)
    const respondedDates = Array.from(new Set(flattenedData.map((d) => d.date.getTime())))
      .map((t) => new Date(t))
      .sort((a, b) => a - b)

    // Compute empty dates (dates that have no response values)
    const respondedSet = new Set(respondedDates.map((d) => d.getTime()))
    const emptyDates = allDates.filter((date) => !respondedSet.has(date.getTime()))

    // Define scales with all dates
    const xScale = d3.scaleBand().domain(allDates).range([0, innerWidth]).padding(0.2)
    const yScale = d3.scalePoint().domain(this.data.choices).range([innerHeight, 0]).padding(0.5)

    // Draw horizontal grid lines for y-axis
    chartGroup
      .selectAll(".y-grid-line")
      .data(this.data.choices)
      .enter()
      .append("line")
      .attr("class", "y-grid-line")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "3")

    // Draw vertical grid lines for dates with responses
    chartGroup
      .selectAll(".x-grid-line")
      .data(respondedDates)
      .enter()
      .append("line")
      .attr("class", "x-grid-line")
      .attr("x1", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("x2", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "3")

    // Draw vertical dashed lines for dates with no responses (missing data)
    chartGroup
      .selectAll(".empty-date-line")
      .data(emptyDates)
      .enter()
      .append("line")
      .attr("class", "empty-date-line")
      .attr("x1", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("x2", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "#EEE")
      .attr("stroke-dasharray", "3")

    // Conditionally filter tick values: show every 2nd date if there are more than 20 dates.
    let tickValues = allDates
    if (allDates.length > 20) {
      tickValues = allDates.filter((d, i) => i % 2 === 0)
    }

    // Draw axes without lines and ticks
    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(tickValues)
      .tickFormat((d) => d3.timeFormat("%m/%d")(d))
      .tickSize(0)

    const yAxis = d3.axisLeft(yScale).tickSize(0)

    chartGroup
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("path, .tick line")
      .remove()
      .style("font-size", "10px")

    const yAxisGroup = chartGroup.append("g").call(yAxis)
    yAxisGroup.selectAll("path, .tick line").remove()
    yAxisGroup
      .selectAll(".tick text")
      .style("font-size", "10px")
      .attr("text-anchor", "start")
      .attr("x", -this.margin.left + 10)

    // Wrap text for choices
    yAxisGroup.selectAll(".tick text").call(this.wrapText, this.margin.left - 10)

    // Draw dots for responses
    chartGroup
      .selectAll("circle")
      .data(flattenedData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.choice))
      .attr("r", 6)
      .attr("fill", "#000000")

    // Tooltip: create a hidden div for displaying data on hover
    const tooltip = d3
      .select(this.element)
      .append("div")
      .attr("class", "choices-history-tooltip")
      .style("position", "absolute")
      .style("padding", "8px 12px")
      .style("background", "#000")
      .style("color", "#fff")
      .style("font-size", "12px")
      .style("border-radius", "12px")
      .style("pointer-events", "none")
      .style("opacity", 0)

    // Vertical dash line for tooltip
    const tooltipLine = chartGroup
      .append("line")
      .attr("class", "tooltip-line")
      .attr("stroke", "#000")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3")
      .style("opacity", 0)

    // Add overlay rectangle to handle tooltip events (following ActivityGraphD3's pattern)
    chartGroup
      .append("rect")
      .attr("class", "overlay")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", (event) => this.handleMouseMove(event, flattenedData, xScale, tooltip, tooltipLine))
      .on("mouseout", () => {
        tooltip.style("opacity", 0)
        tooltipLine.style("opacity", 0)
      })
  }

  // Function to wrap text for y-axis labels
  wrapText(textNodes, maxWidth) {
    textNodes.each(function () {
      const text = d3.select(this)
      const words = text.text().split(/\s+/).reverse()
      let word
      let line = []
      const lineHeight = 1.0 // Constant line height for every new line
      const x = text.attr("x")
      const y = text.attr("y")
      const dy = parseFloat(text.attr("dy") || 0)

      // Clear the text content
      text.text(null)

      // Create the first tspan with the original dy
      let tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", `${dy}em`).style("font-size", "12px")

      while ((word = words.pop())) {
        line.push(word)
        tspan.text(line.join(" "))
        if (tspan.node().getComputedTextLength() > maxWidth) {
          line.pop()
          tspan.text(line.join(" "))
          line = [word]
          // For subsequent lines, use a constant lineHeight
          tspan = text
            .append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", `${lineHeight}em`)
            .text(word)
            .style("font-size", "12px")
        }
      }
    })
  }

  // Handle mouse move event for tooltip
  handleMouseMove(event, flattenedData, xScale, tooltip, tooltipLine) {
    // Clear any existing tooltip
    d3.selectAll(".choices-history-tooltip").style("opacity", 0)

    const [xPos] = d3.pointer(event)
    // Use the unique dates from the xScale domain
    const dates = xScale.domain()
    const xPositions = dates.map((date) => xScale(date) + xScale.bandwidth() / 2)
    const index = xPositions.reduce(
      (prev, curr, currIndex) => (Math.abs(curr - xPos) < Math.abs(xPositions[prev] - xPos) ? currIndex : prev),
      0,
    )
    const selectedDate = dates[index]
    const formatDate = d3.timeFormat("%m/%d")

    // Filter the flattened data to get all choices if there are multiple
    const choicesForDate = flattenedData.filter((d) => d.date.getTime() === selectedDate.getTime())
    let tooltipContent = `${formatDate(selectedDate)}`

    tooltip
      .html(tooltipContent)
      .style("left", `${event.pageX + 15}px`)
      .style("top", `${event.pageY - 20}px`)
      .style("opacity", 1)

    // Add a vertical dash line to follow the tooltip's date
    tooltipLine
      .attr("x1", xScale(selectedDate) + xScale.bandwidth() / 2)
      .attr("x2", xScale(selectedDate) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", this.height - this.margin.top - this.margin.bottom)
      .style("opacity", 0.4)
  }
}

export default ChoicesHistoryD3
