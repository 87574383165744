import { faEyeLowVision } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row } from "@tanstack/react-table"

import InspectPermissionsDialog from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/InspectPermissionsDialog.tsx"

import { useDialog } from "@utilities/shadcn-utils.ts"

interface CheckPermissionActionProps {
  row: Row<ListUser>
}

const CheckPermissionAction: React.FC<CheckPermissionActionProps> = ({ row }) => {
  const inspectPermissionsDialog = useDialog()

  const tooltipStyle = `
    absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 
    group-hover:opacity-100 transition-opacity duration-300 pointer-events-none
  `
  const tooltipTextStyle = "bg-black text-white rounded-full text-nowrap px-3 py-1 text-sm"
  const iconStyle = "text-lg text-neutral-300 hover:text-neutral-950 transition w-6"

  return (
    <>
      <InspectPermissionsDialog row={row} triggerProps={inspectPermissionsDialog.props} />
      <div className="relative group inline-block">
        {/* button */}
        <button className={iconStyle} onClick={inspectPermissionsDialog.trigger}>
          <FontAwesomeIcon icon={faEyeLowVision} />
        </button>
        {/* tooltip */}
        <div className={tooltipStyle}>
          <div className={tooltipTextStyle}>Check Permissions</div>
        </div>
      </div>
    </>
  )
}

export default CheckPermissionAction
