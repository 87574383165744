import { create } from "zustand"

import api from "@utilities/api"

interface StarredChartsStore {
  currentStarredCharts: Set<string>
  toggleStarredChart: (chartId: string, clientId: string) => Promise<void>
  setStarredCharts: (charts: Set<string>) => void
}

const useStarredChartsStore = create<StarredChartsStore>((set) => ({
  currentStarredCharts: new Set<string>(),
  setStarredCharts: (charts: Set<string>) => set({ currentStarredCharts: charts }),
  toggleStarredChart: async (chartId: string, clientId: string) => {
    try {
      const result = await api.post(`/insight/starred-charts/toggle/${clientId}`, {
        chart_key: chartId,
      })

      if (result.status === 200) {
        set((state) => {
          const updatedCharts = new Set(state.currentStarredCharts)
          // Toggle the chart: remove it if it exists, otherwise add it.
          if (updatedCharts.has(chartId)) {
            updatedCharts.delete(chartId)
          } else {
            updatedCharts.add(chartId)
          }
          return { currentStarredCharts: updatedCharts }
        })
      }
    } catch (err) {
      console.error("Error toggling starred chart", err)
    }
  },
}))

export default useStarredChartsStore
