import SurveySection from "./SurveySection"
import useSurveysInsightStore from "./store/SurveysInsightStore"

const SurveyPage: React.FC = () => {
  const resetSurveyDateRange = useSurveysInsightStore((state) => state.resetDateRange)
  resetSurveyDateRange()

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Survey</h3>
      </div>
      <SurveySection />
    </div>
  )
}

export default SurveyPage
