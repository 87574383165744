import { faArrowUp, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useParams } from "react-router-dom"

import SectionContainer from "../Insight/components/atom/SectionContainer"
import useTranscriptCommentsStore from "./store/TranscriptCommentsStore"

const TranscriptComments: React.FC = () => {
  const { recordingId } = useParams<{ recordingId: string }>()
  const queryClient = useQueryClient()
  const currentTranscriptComments = useTranscriptCommentsStore((state) => state.currentTranscriptComments)
  const addComment = useTranscriptCommentsStore((state) => state.addComment)
  const [newComment, setNewComment] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  // Now that all hooks are called, you can conditionally render
  if (!recordingId) return null

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (newComment.trim() === "") return

    setIsLoading(true)
    try {
      await addComment(newComment, recordingId, queryClient)
      setNewComment("")
    } catch (error: unknown) {
      console.error("Error adding comment")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <SectionContainer title="Comments">
        {/* Comment history */}
        <div className="">
          {currentTranscriptComments?.map((comment) => (
            <div
              key={comment.comment_id}
              className="bg-white overflow-hidden p-3 first:rounded-t-xl first:border-t last:rounded-b-xl last:border-b not-last:border-b border-x"
            >
              {/* Comment header */}
              <div className="flex items-center justify-start text-xs text-neutral-300 gap-2">
                <div>{comment.role.toUpperCase()}</div>
                <div>{comment.created_at}</div>
              </div>
              {/* Comment message */}
              <div>
                <div>{comment.message}</div>
              </div>
            </div>
          ))}
        </div>
        {/* Comment Creator */}
        <form
          onSubmit={(e) => {
            void handleSubmit(e)
          }}
          className="mt-4 relative"
        >
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className="w-full p-4 pr-10 border rounded-xl focus:outline-none resize-none focus:border-neutral-100 focus:shadow-xl transition duration-500"
            rows={3}
          />
          <button
            type="submit"
            disabled={!newComment.trim() || isLoading}
            className="absolute bottom-4 right-2 p-1 bg-neutral-950 text-white rounded-full hover:bg-neutral-800 transition active:bg-neutral-950 text-sm w-8 h-8"
          >
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faArrowUp} />}
          </button>
        </form>
      </SectionContainer>
    </div>
  )
}

export default TranscriptComments
