import { create } from "zustand"

interface TranscriptListStore {
  currentTranscriptList: Transcript[]
  setCurrentTranscriptList: (transcriptList: Transcript[]) => void
}

const useTranscriptListStore = create<TranscriptListStore>((set) => ({
  currentTranscriptList: [],
  setCurrentTranscriptList: (currentTranscriptList) => set({ currentTranscriptList }),
}))

export default useTranscriptListStore
