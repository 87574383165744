import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useDeviceStore from "../store/DeviceStore.ts"

const DeviceProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentDeviceResponse = useDeviceStore((state) => state.setCurrentDeviceResponse)
  const { clientId } = useParams()

  const {
    data: deviceResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["deviceData", clientId],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/device/${clientId}`
      const { data, status } = await api.get<DeviceResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching device data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && deviceResponse) {
      setCurrentDeviceResponse(deviceResponse)
    }
  }, [deviceResponse, isSuccess, setCurrentDeviceResponse])

  if (isLoading)
    return (
      <div className="py-4">
        <LoadingSpinner size="1x" />
      </div>
    )
  if (isError) return <div className="my-6 w-full text-center text-sm text-neutral-200">Failed to load device info</div>

  // add is reponse is empty
  if (deviceResponse?.hardware_versions?.length === 0)
    return <div className="my-6 w-full text-center text-sm text-neutral-200">No device info found</div>

  return <>{children}</>
}

export default DeviceProvider
