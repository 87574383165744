import { create } from "zustand"

interface DeviceStore {
  currentDeviceResponse: DeviceResponse | null
  setCurrentDeviceResponse: (response: DeviceResponse) => void
}
const useDeviceStore = create<DeviceStore>((set) => ({
  currentDeviceResponse: null,
  setCurrentDeviceResponse: (currentDeviceResponse) => set({ currentDeviceResponse }),
}))
export default useDeviceStore
