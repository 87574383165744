import * as d3 from "d3"

import { brightenColor } from "./utils"

class ActivityGraphWithMinMaxD3 {
  constructor(element, width, height, data, color = "#5E89FF") {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 15, right: 10, bottom: 40, left: 40 }
    this.svg = null
    this.unit = data[0].unit
    this.color = color

    this.createChart()
  }

  formatValue = (value) => {
    // if value > 1000, convert to k
    if (value > 1000) {
      return `${(value / 1000).toFixed(1)}k`
    }
    if (Number.isInteger(value)) {
      return value.toFixed(0)
    }
    return value.toFixed(1)
  }

  createChart() {
    // Remove old chart if it exists
    d3.select(this.element).select("svg").remove()

    // Configuration
    const showAllDots = true

    // Set up SVG canvas
    const svg = d3
      .select(this.element)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`)
      .style("user-select", "none")

    this.svg = svg

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    const parseDate = d3.timeParse("%Y-%m-%d")
    // Prepare and sort data
    const data = this.data.map((d) => ({
      date: parseDate(d.date),
      value: d.value === 0 ? null : d.value,
      min: d.min,
      max: d.max,
    }))
    data.sort((a, b) => a.date - b.date)

    // Set up scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.date))
      .range([0, innerWidth])
      .padding(0.6)

    const yMax = d3.max(data, (d) => (d.max != null ? d.max : d.value)) || 0
    const yScale = d3.scaleLinear().domain([0, yMax]).nice().range([innerHeight, 0])

    // Grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .attr("color", "#E7E7E7")
      .attr("stroke-dasharray", "3, 3")
      .call(d3.axisLeft(yScale).ticks(5).tickSize(-innerWidth).tickFormat(""))
      .call((g) => g.select(".domain").remove())

    svg
      .append("g")
      .attr("class", "grid")
      .attr("color", "#E7E7E7")
      .attr("stroke-dasharray", "3, 3")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale).tickSize(-innerHeight).tickFormat(""))

    // Draw min-max range
    svg
      .selectAll(".min-max-rect")
      .data(data.filter((d) => d.min != null && d.max != null))
      .enter()
      .append("rect")
      .attr("class", "min-max-rect")
      .attr("x", (d) => xScale(d.date))
      .attr("y", (d) => yScale(d.max))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => yScale(d.min) - yScale(d.max))
      .attr("rx", xScale.bandwidth() / 2)
      .attr("ry", xScale.bandwidth() / 2)
      .attr("fill", this.color)

    // add min-max range label
    svg
      .selectAll(".max-label")
      .data(data.filter((d) => d.max != null))
      .enter()
      .append("text")
      .attr("class", "max-label")
      .attr("x", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.max) - 4)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .attr("fill", this.color)
      .text((d) => `${this.formatValue(d.max)}`)

    // MIN label (below the bottom edge of the rect)
    svg
      .selectAll(".min-label")
      .data(data.filter((d) => d.min != null))
      .enter()
      .append("text")
      .attr("class", "min-label")
      .attr("x", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.min) + 12)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .attr("fill", this.color)
      .text((d) => `${this.formatValue(d.min)}`)

    // Drawing avg dots
    if (showAllDots) {
      svg
        .selectAll(".dot-all")
        .data(data.filter((d) => d.value !== null))
        .enter()
        .append("circle")
        .attr("class", "dot-all")
        .attr("cx", (d) => xScale(d.date) + xScale.bandwidth() / 2)
        .attr("cy", (d) => yScale(d.value))
        .attr("r", (xScale.bandwidth() / 2) * 0.4)
        .attr("fill", "#FFDFE7")
    }

    // tooltip
    const tooltip = d3
      .select(this.element)
      .append("div")
      .attr("class", "activity-graph-tooltip")
      .style("position", "absolute")
      .style("padding", "10px 12px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("color", "#fff")
      .style("font-size", "14px")
      .style("pointer-events", "none")
      .style("opacity", 0)

    // Vertical line & focus circle
    const focusLine = svg
      .append("line")
      .attr("class", "focusLine")
      .attr("stroke", "#000")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3")
      .style("opacity", 0)

    const focusCircle = svg
      .append("circle")
      .attr("r", (xScale.bandwidth() / 2) * 0.5)
      .attr("fill", "#fff")
      .style("opacity", 0)

    // Interaction layer
    svg
      .append("rect")
      .attr("class", "overlay")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", (event) => this.handleMouseMove(event, data, xScale, yScale, tooltip, focusLine, focusCircle))
      .on("mouseout", () => {
        tooltip.style("opacity", 0)
        focusLine.style("opacity", 0)
        focusCircle.style("opacity", 0)
      })

    // Axes
    const dayAbbreviations = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    const xAxis = d3.axisBottom(xScale).tickFormat((d) => dayAbbreviations[d.getDay()])
    const yAxis = d3.axisLeft(yScale).ticks(5)

    const xAxisG = svg.append("g").attr("transform", `translate(0, ${innerHeight})`).call(xAxis)
    xAxisG.selectAll("path, line").style("stroke", "#888888")
    xAxisG.selectAll("text").attr("dy", "1em").style("text-anchor", "middle").style("color", "#888888")

    // Highlight weekend days on x-axis
    xAxisG.selectAll(".tick").each(function (d) {
      const day = d.getDay()
      if (day === 0 || day === 6) {
        const tick = d3.select(this)
        const rectHeight = 16
        const rectWidth = 18
        const roundRadius = 6

        tick
          .insert("rect", "text")
          .attr("x", -rectWidth / 2)
          .attr("y", rectHeight / 2)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", roundRadius)
          .attr("ry", roundRadius)
          .style("fill", "black")

        tick.select("text").style("fill", "white")
      }
    })

    // Additional date below weekday labels
    xAxisG
      .selectAll(".tick")
      .append("text")
      .attr("dy", "35px")
      .attr("font-size", "10px")
      .attr("fill", "#888888")
      .text((d) => d3.timeFormat("%m/%d")(d))

    const yAxisG = svg.append("g").call(yAxis)
    yAxisG.selectAll("path, line").style("stroke", "#888888")
    yAxisG.selectAll("text").style("fill", "#888888")
  }

  handleMouseMove(event, data, xScale, yScale, tooltip, focusLine, focusCircle) {
    d3.selectAll(".activity-graph-tooltip").style("opacity", 0)
    const [xPos] = d3.pointer(event)

    // find closest index
    const xPositions = data.map((d) => xScale(d.date) + xScale.bandwidth() / 2)
    const index = xPositions.reduce((prevIndex, currX, currIndex) => {
      return Math.abs(currX - xPos) < Math.abs(xPositions[prevIndex] - xPos) ? currIndex : prevIndex
    }, 0)

    const d = data[index]
    if (!d) return

    // Format date
    const formatDate = d3.timeFormat("%m/%d")

    // Show tooltip
    tooltip
      .html(
        `<span style="color: ${brightenColor(this.color, 0.5)};">${formatDate(d.date)}</span></br> <span>Max ${
          d.value === null ? "? " : Number.isInteger(d.max) ? d.max : d.max.toFixed(1)
        }${this.unit}</span></br><span>Min ${
          d.value === null ? "? " : Number.isInteger(d.min) ? d.min : d.min.toFixed(1)
        }${this.unit}</span></br><span>Avg ${
          d.value === null ? "? " : Number.isInteger(d.value) ? d.value : d.value.toFixed(1)
        }${this.unit}</span></br>`,
      )
      .style("left", `${event.pageX - 40}px`)
      .style("top", `${event.pageY - 130}px`)
      .style("opacity", 1)

    // Vertical line
    focusLine
      .attr("x1", xScale(d.date) + xScale.bandwidth() / 2)
      .attr("x2", xScale(d.date) + xScale.bandwidth() / 2)
      .attr("y1", 0 - this.margin.top)
      .attr("y2", this.height)
      .style("opacity", 0.4)

    // Focus circle
    if (d.value !== null && d.value !== 0) {
      focusCircle
        .attr("cx", xScale(d.date) + xScale.bandwidth() / 2)
        .attr("cy", yScale(d.value))
        .style("opacity", 1)
    } else {
      focusCircle.style("opacity", 0)
    }
  }
}

export default ActivityGraphWithMinMaxD3
