import { Link, useParams } from "react-router-dom"

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/shadcn-components/ui/accordion"

import MarkdownRenderer from "@features/Transcript/MarkdownRenderer"

interface TranscriptItemCardProps {
  transcript: Transcript
}

const TranscriptItemCard: React.FC<TranscriptItemCardProps> = ({ transcript }) => {
  const { clientId } = useParams()
  const { title, created_at, summary, attendees, transcription_start_time, transcription_end_time, recording_id } =
    transcript
  const dateText = created_at.split("T")[0].split("-").join("/")
  const timeText = created_at.split("T")[1].split(":").slice(0, 2).join(":")
  // Duration text as hh:mm:ss
  const durationText = new Date((transcription_end_time - transcription_start_time) * 1000).toISOString().substr(11, 8)
  return (
    <div className="border mb-4 rounded-xl m-2 p-4 bg-white">
      <Accordion type="single" collapsible className="w-full">
        <div className="flex flex-row justify-between">
          <div className="text-xl font-bold px-2">{title}</div>
          <Link to={`/transcript/${clientId}/${recording_id}`}>
            <div className="flex items-center rounded-full border px-3 hover:shadow-md hover:bg-neutral-100">View</div>
          </Link>
        </div>
        <div className="flex flex-row text-xs space-x-2 px-2 text-neutral-300">
          <span>{attendees.map((attendee) => attendee.name).join(", ")}</span>

          <span>/</span>
          <span>
            {dateText} {timeText}
          </span>

          <span>/</span>
          <span>{durationText}</span>
        </div>
        <AccordionItem value="item-1" className="border-0 mb-0">
          <AccordionTrigger className="text-base py-1 px-2 border-0 ">Summary</AccordionTrigger>
          <AccordionContent className="px-2 mt-0 border-0">
            <MarkdownRenderer content={summary} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default TranscriptItemCard
