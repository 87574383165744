import { QueryClient } from "@tanstack/react-query"
import { create } from "zustand"

import api from "@/utilities/api"

interface TranscriptCommentsStore {
  currentTranscriptComments: TranscriptComment[] | null
  setCurrentTranscriptComments: (comments: TranscriptComment[] | null) => void
  addComment: (message: string, recording_id: string, queryClient: QueryClient) => Promise<void>
}

const useTranscriptCommentsStore = create<TranscriptCommentsStore>((set) => ({
  currentTranscriptComments: null,
  setCurrentTranscriptComments: (comments) => set({ currentTranscriptComments: comments }),
  addComment: async (message: string, recording_id: string, queryClient: QueryClient) => {
    try {
      const result = await api.post(`/sessions/comments/${recording_id}`, {
        message: message,
      })

      if (result.status === 200) {
        void queryClient.invalidateQueries({ queryKey: ["transcriptComments"] })
      } else {
        throw new Error("Failed to post comment")
      }
    } catch (error: unknown) {
      throw new Error("Error posting comment")
    }
  },
}))

export default useTranscriptCommentsStore
