import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactElement, cloneElement } from "react"

const Modal = ({
  isOpen,
  title,
  children = <></>,
  footer,
  onClose,
  size = "sm",
  modalClassOverrides = "",
}: InteractiveModalProps) => {
  if (!isOpen) return null

  let sizedModalClasses = modalClassOverrides

  if (size === "lg")
    sizedModalClasses +=
      " min-w-[800px] w-[60vw] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"
  if (size === "md")
    sizedModalClasses +=
      " min-w-[600px] w-[40vw] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"

  if (size === "sm")
    sizedModalClasses +=
      " min-w-[400px] w-[30vw] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"

  return (
    <div className="hs-overlay bg-black/50 w-full h-full fixed top-0 start-0 z-[60] overflow-x-hidden overflow-y-auto pointer-events-auto">
      <div className="opacity-100 duration-500 transition-all sm:max-w-lg sm:w-full md:max-w-[90%] mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-[calc(100%-4rem)] flex items-center justify-center">
        <div className={sizedModalClasses}>
          <div className="flex justify-between items-center py-3 px-4 border-b border-gray-100 dark:border-gray-700">
            <h3 className="font-bold m-2 text-gray-800 dark:text-white">{title}</h3>
            <button
              type="button"
              className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <FontAwesomeIcon className={"text-lg font-normal text-gray-500"} icon={faTimes} />
            </button>
          </div>
          <div className="p-4 overflow-y-auto" style={{ maxHeight: "calc(100vh - 10rem)" }}>
            {cloneElement(children as ReactElement, { onClose })}
          </div>
          {footer && (
            <div className={"flex justify-end items-center py-3 px-4 border-t dark:border-gray-700"}>{footer}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
