import TranscriptDetailProvider from "@/features/Transcript/provider/TranscriptDetailProvider"

import TranscriptDetailRenderer from "./TranscriptDetailRenderer"

const TrasncriptPage: React.FC = () => {
  return (
    <TranscriptDetailProvider>
      <TranscriptDetailRenderer />
    </TranscriptDetailProvider>
  )
}
export default TrasncriptPage
