import SectionContainer from "@features/Insight/components/atom/SectionContainer"
import MarkdownRenderer from "@features/Transcript/MarkdownRenderer"

interface TranscriptSummaryProps {
  summary: string
}

const TranscriptSummary: React.FC<TranscriptSummaryProps> = ({ summary }) => {
  return (
    <SectionContainer title="Summary">
      <div className="bg-white p-6 pt-8 rounded-lg border">
        <MarkdownRenderer content={summary} />
      </div>
    </SectionContainer>
  )
}

export default TranscriptSummary
