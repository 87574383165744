import CardioSection from "../starred/CardioSection"
import SleepAndStepsSection from "../starred/SleepAndStepsSection"
import useActiveCaloriesSummaryStore from "../summary/store/ActiveCaloriesSummaryStore"
import useExerciseSummaryStore from "../summary/store/ExerciseSummaryStore"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import useActivityInsightStore from "./store/ActivityInsightStore"

const ActivityPage: React.FC = () => {
  // Extract the reset functions from each store
  const resetActivityDateRange = useActivityInsightStore((state) => state.resetDateRange)
  const resetActiveCaloriesSummaryDateRange = useActiveCaloriesSummaryStore((state) => state.resetDateRange)
  const resetExerciseSummaryDateRange = useExerciseSummaryStore((state) => state.resetDateRange)
  const resetHRVSummaryDateRange = useHRVSummaryStore((state) => state.resetDateRange)
  const resetHeartRateSummaryDateRange = useHeartRateSummaryStore((state) => state.resetDateRange)
  const resetSleepSummaryDateRange = useSleepSummaryStore((state) => state.resetDateRange)
  const resetStepSummaryDateRange = useStepSummaryStore((state) => state.resetDateRange)

  // Call the reset functions when the component mounts
  resetActivityDateRange()
  resetActiveCaloriesSummaryDateRange()
  resetExerciseSummaryDateRange()
  resetHRVSummaryDateRange()
  resetHeartRateSummaryDateRange()
  resetSleepSummaryDateRange()
  resetStepSummaryDateRange()

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Activity</h3>
      </div>

      <SleepAndStepsSection />
      <CardioSection />
    </div>
  )
}

export default ActivityPage
