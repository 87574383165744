import { ReactNode } from "react"

interface ClientOverviewContainerProps {
  title?: string
  description?: string
  marginSize?: "sm" | "md" | "lg"
  children: ReactNode
  headerControls?: ReactNode
}
const ClientOverviewContainer = ({
  title,
  children,
  headerControls,
  description,
  marginSize = "sm",
}: ClientOverviewContainerProps) => {
  const showSectionHeader = title || description
  const rowMargin = marginSize === "sm" ? "mb-3" : marginSize === "md" ? "mb-6" : "mb-9"
  const containerStyle = `w-full bg-neutral-50 ${rowMargin} rounded-xl p-4 min-w-[600px] not-last:mb-4`
  return (
    <div className={containerStyle}>
      <div className="flex flex-row justify-between">
        {showSectionHeader && (
          <div className="flex flex-row w-full gap-2 justify-start mb-2 ml-1">
            <div className="text-neutral-500">{title}</div>
            <div className="text-neutral-300 text-sm pt-1">{description}</div>
          </div>
        )}
        {headerControls && <div className="flex w-full justify-end">{headerControls}</div>}
      </div>
      {children}
    </div>
  )
}

export default ClientOverviewContainer
