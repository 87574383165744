import * as d3 from "d3"
import { round } from "lodash"

class ActivityStackGraphD3 {
  constructor(element, width, height, data) {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 20, right: 10, bottom: 70, left: 40 }
    this.svg = null
    this.innerWidth = 0
    this.innerHeight = 0

    this.createChart()
  }

  createChart() {
    d3.select(this.element).select("svg").remove()

    if (!this.data || Object.keys(this.data).length === 0) {
      console.warn("Empty data, skipping chart creation.")
      return
    }

    // Use dense mode if the number of dates exceeds 30.
    const denseMode = Object.keys(this.data).length > 30
    this.denseMode = denseMode

    const svg = d3
      .select(this.element)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .style("user-select", "none")

    const chartGroup = svg.append("g").attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    this.svg = chartGroup

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    this.innerWidth = innerWidth
    this.innerHeight = innerHeight

    const parseDate = d3.timeParse("%Y-%m-%d")

    // Prepare data
    const dates = Object.keys(this.data).map((d) => ({
      date: parseDate(d),
      activities: this.data[d],
    }))

    // Define scales
    const totalMinutesPerDay = 24 * 60

    const xScale = d3
      .scaleBand()
      .domain(dates.map((d) => d.date))
      .range([0, innerWidth])
      .padding(0.1)

    const yScale = d3.scaleLinear().domain([totalMinutesPerDay, 0]).range([innerHeight, 0])

    // Define color scales
    const colorScaleSleep = d3
      .scaleOrdinal()
      .domain(["REM", "Core", "Deep", "In Bed", "Awake", "Asleep"])
      .range(["#B7D3FF", "#5E89FF", "#0D1FF5", "#FFDB88", "#FF6D5C", "#8CE1FF"])

    const colorScaleSteps = d3.scaleSequential(d3.interpolateGreens).domain([0, 3000])

    // Create axes
    const dayAbbreviations = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d) =>
        this.denseMode ? (d.getDay() === 1 ? dayAbbreviations[d.getDay()] : "") : dayAbbreviations[d.getDay()],
      )

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(d3.range(0, 24 * 60, 180))
      .tickFormat((d) => {
        const hours = Math.floor(d / 60)
        return `${hours}:00`
      })

    // Draw activities
    dates.forEach((d, i) => {
      const activities = d.activities.filter((a) => !(a.type === "steps" && (!a.value || a.value === 0)))

      chartGroup
        .selectAll(`.bar-${i}`)
        .data(activities)
        .enter()
        .append("rect")
        .attr("x", xScale(d.date))
        .attr("y", (a) => yScale(this.getMinutesFromTime(a.start_time)))
        .attr("width", xScale.bandwidth())
        .attr("height", (a) => {
          const startMinutes = this.getMinutesFromTime(a.start_time)
          const endMinutes = this.getMinutesFromTime(a.end_time)
          const y0 = yScale(startMinutes)
          const y1 = yScale(endMinutes)
          return Math.abs(y1 - y0) + 1
        })
        .attr("fill", (a) => (a.type === "sleep" ? colorScaleSleep(a.level) : colorScaleSteps(a.value || 0)))
    })

    // Append axes
    const xAxisG = chartGroup.append("g").attr("transform", `translate(0, ${innerHeight})`).call(xAxis)
    xAxisG.selectAll("path, line").style("stroke", "#888888")
    xAxisG.selectAll("text").attr("dy", "1em").style("text-anchor", "middle").style("color", "#888888")

    // Add weekend highlighting only when not in dense mode
    if (!this.denseMode) {
      xAxisG.selectAll(".tick").each(function (d) {
        const day = d.getDay()
        if (day === 0 || day === 6) {
          const tick = d3.select(this)
          const rectHeight = 16
          const rectWidth = 18
          const roundRadius = 6

          // Insert a rounded rectangle behind the text
          tick
            .insert("rect", "text")
            .attr("x", -rectWidth / 2)
            .attr("y", rectHeight / 2)
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", roundRadius)
            .attr("ry", roundRadius)
            .style("fill", "black")

          // Change text color to white
          tick.select("text").style("fill", "white")
        }
      })
    }

    // Add additional date below the weekday labels.
    xAxisG
      .selectAll(".tick")
      .append("text")
      .attr("dy", "35px")
      .attr("font-size", "10px")
      .attr("font-family", "sans-serif")
      .attr("fill", "#888888")
      .text((d) => (this.denseMode ? (d.getDay() === 1 ? d3.timeFormat("%m/%d")(d) : "") : d3.timeFormat("%m/%d")(d)))

    const yAxisG = chartGroup.append("g").call(yAxis)
    yAxisG.selectAll("path, line").style("stroke", "#888888")
    yAxisG.selectAll("text").style("fill", "#888888")

    // Add vertical dashed lines for two sides of weekend bars

    const xStep = xScale.step() // distance between the start of adjacent bands
    const gap = xStep - xScale.bandwidth()

    const isWeekend = (date) => {
      const day = date.getDay()
      return day === 0 || day === 6
    }

    // Group contiguous weekend days
    const weekendBlocks = []
    let currentBlock = []
    dates.forEach((d) => {
      if (isWeekend(d.date)) {
        currentBlock.push(d)
      } else {
        if (currentBlock.length > 0) {
          weekendBlocks.push(currentBlock)
          currentBlock = []
        }
      }
    })
    if (currentBlock.length > 0) weekendBlocks.push(currentBlock)

    // For each weekend block, add vertical dashed lines at its left and right boundaries.
    weekendBlocks.forEach((block) => {
      const firstDate = block[0].date
      const lastDate = block[block.length - 1].date
      // Get the x positions of the first and last weekend columns.
      let leftBoundary = xScale(firstDate)
      let rightBoundary = xScale(lastDate) + xScale.bandwidth()
      // Shift boundaries to lie in the middle of the gap.
      leftBoundary -= gap / 2
      rightBoundary += gap / 2

      // Add vertical dash-lines (left side)
      chartGroup
        .append("line")
        .attr("x1", leftBoundary)
        .attr("y1", 0)
        .attr("x2", leftBoundary)
        .attr("y2", innerHeight)
        .style("stroke", "#BBB")
        .style("stroke-dasharray", "3,3")
        .style("pointer-events", "none")
      // Add vertical dash-lines (right side)
      chartGroup
        .append("line")
        .attr("x1", rightBoundary)
        .attr("y1", 0)
        .attr("x2", rightBoundary)
        .attr("y2", innerHeight)
        .style("stroke", "#BBB")
        .style("stroke-dasharray", "3,3")
        .style("pointer-events", "none")
    })

    // Add legends
    this.addSleepLegend(svg, colorScaleSleep)
    this.addStepsLegend(svg, colorScaleSteps)

    // Add tooltip
    this.addTooltip(chartGroup)
  }

  getMinutesFromTime(time) {
    const [hours, minutes] = time.split(":").map(Number)
    return hours * 60 + minutes
  }

  addTooltip(svg) {
    const tooltip = d3
      .select(this.element)
      .append("div")
      .attr("class", "activity-stack-tooltip")
      .style("position", "absolute")
      .style("padding", "4px 12px")
      .style("background", "#000")
      .style("border-radius", "12px")
      .style("color", "#fff")
      .style("font-size", "14px")
      .style("pointer-events", "none")
      .style("opacity", 0)

    // Lines for start and end times
    let tooltipLineStart = null
    let tooltipLineEnd = null

    svg
      .selectAll("rect")
      .on("mouseover", (event, d) => {
        d3.selectAll(".activity-stack-tooltip").style("opacity", 0)
        const value = round(d.value, 0)
        const activityText = d.type === "sleep" ? `Sleep Stage: ${d.level}` : `Steps: ${value}`

        // Show tooltip
        tooltip
          .html(
            `${d.date.split("-")[1] + "/" + d.date.split("-")[2]} <br> ${d.start_time} - ${d.end_time} <br/> ${activityText}`,
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 60}px`)
          .transition()
          .style("opacity", 1)

        const startY = this.getMinutesFromTime(d.start_time)
        const endY = this.getMinutesFromTime(d.end_time)

        const yScaledStart = d3
          .scaleLinear()
          .domain([24 * 60, 0])
          .range([this.innerHeight, 0])(startY)

        const yScaledEnd = d3
          .scaleLinear()
          .domain([24 * 60, 0])
          .range([this.innerHeight, 0])(endY)

        // Add dashed line at start time
        tooltipLineStart = svg
          .append("line")
          .attr("class", "tooltip-line-start")
          .attr("x1", 0)
          .attr("y1", yScaledStart)
          .attr("x2", this.innerWidth)
          .attr("y2", yScaledStart)
          .style("stroke", "#888")
          .style("stroke-dasharray", "3,3")
          .style("pointer-events", "none")
          .style("opacity", 1)

        // Add dashed line at end time
        tooltipLineEnd = svg
          .append("line")
          .attr("class", "tooltip-line-end")
          .attr("x1", 0)
          .attr("y1", yScaledEnd)
          .attr("x2", this.innerWidth)
          .attr("y2", yScaledEnd)
          .style("stroke", "#888")
          .style("stroke-dasharray", "3,3")
          .style("pointer-events", "none")
          .style("opacity", 1)
      })
      .on("mouseout", () => {
        tooltip.transition().style("opacity", 0)

        if (tooltipLineStart) {
          tooltipLineStart.remove()
          tooltipLineStart = null
        }
        if (tooltipLineEnd) {
          tooltipLineEnd.remove()
          tooltipLineEnd = null
        }
      })
  }

  addSleepLegend(svg, colorScaleSleep) {
    const sleepLegend = svg.append("g").attr("transform", `translate(${this.margin.left}, ${this.height - 20})`)
    const sleepDataLevels = ["Deep", "Core", "REM", "Asleep", "In Bed", "Awake"]

    sleepLegend
      .append("text")
      .text("Sleep Stages")
      .style("font-size", "14px")
      .style("font-family", "sans-serif")
      .style("font-weight", "bold")
      .style("fill", "#888888")
      .attr("x", 0)
      .attr("y", 14)

    sleepDataLevels.forEach((level, i) => {
      const legendGroup = sleepLegend.append("g").attr("transform", `translate(${i * 70 + 95}, 0)`)

      legendGroup
        .append("rect")
        .attr("width", 18)
        .attr("height", 18)
        .attr("rx", 5)
        .attr("ry", 5)
        .attr("fill", colorScaleSleep(level))

      legendGroup
        .append("text")
        .attr("x", 22)
        .attr("y", 14)
        .attr("fill", "#888888")
        .text(level)
        .style("font-size", "12px")
        .style("font-family", "sans-serif")
    })
  }

  addStepsLegend(svg, colorScaleSteps) {
    const gradientLegendWidth = 200
    const gradientLegendHeight = 10
    const gradientLegendX = this.width - 250
    const gradientLegendY = this.height - 15

    const stepsLegend = svg.append("g").attr("transform", `translate(${gradientLegendX}, ${gradientLegendY})`)

    const defs = svg.append("defs")
    const linearGradient = defs
      .append("linearGradient")
      .attr("id", "steps-gradient")
      .attr("x1", "0%")
      .attr("x2", "100%")
      .attr("y1", "0%")
      .attr("y2", "0%")

    const stepValues = [0, 500, 1000, 1500, 2000, 2500, 3000]

    stepValues.forEach((val) => {
      const offset = (val / 3000) * 100
      linearGradient.append("stop").attr("offset", `${offset}%`).attr("stop-color", colorScaleSteps(val))
    })

    stepsLegend
      .append("rect")
      .attr("width", gradientLegendWidth)
      .attr("height", gradientLegendHeight)
      .attr("rx", 5)
      .attr("ry", 5)
      .style("fill", "url(#steps-gradient)")

    stepsLegend
      .append("text")
      .attr("x", -60)
      .attr("y", gradientLegendHeight / 2 + 4)
      .attr("fill", "#888888")
      .text("Steps")
      .style("font-size", "14px")
      .style("font-family", "sans-serif")
      .style("font-weight", "bold")

    stepsLegend
      .append("text")
      .attr("x", -15)
      .attr("y", gradientLegendHeight / 2 + 4)
      .attr("fill", "#888888")
      .text("0")
      .style("font-size", "12px")

    stepsLegend
      .append("text")
      .attr("x", gradientLegendWidth + 5)
      .attr("y", gradientLegendHeight / 2 + 4)
      .attr("fill", "#888888")
      .text("3000")
      .style("font-size", "12px")
  }
}

export default ActivityStackGraphD3
