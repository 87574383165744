import { ActiveCaloriesInfo, HRVInfo } from "@features/Insight/text/InfoTexts.ts"

import ActivityGraph from "../graph/ActivityGraph"
import useActiveCaloriesSummaryStore from "../summary/store/ActiveCaloriesSummaryStore"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore"
import DailyActiveCaloriesProvider from "./provider/DailyActiveCaloriesProvider"
import DailyHRVChartProvider from "./provider/DailyHRVChartProvider"
import DailyHeartRateChartProvider from "./provider/DailyHeartRateChartProvider"

const DailySleepAndStepsCard: React.FC = () => {
  const { currentHRVResponse } = useHRVSummaryStore()
  const hrvChartData = currentHRVResponse?.chart_data || []

  const { currentHeartRateResponse } = useHeartRateSummaryStore()
  const heartRateChartData = currentHeartRateResponse?.chart_data || []

  const { currentActiveCaloriesResponse } = useActiveCaloriesSummaryStore()
  const activeCaloriesChartData = currentActiveCaloriesResponse?.chart_data || []

  return (
    <div className="flex gap-3 flex-col">
      <DailyHeartRateChartProvider>
        <ActivityGraph graphData={heartRateChartData} name="Heart Rate" color="#F85884" useMinMax={true} />
      </DailyHeartRateChartProvider>
      <DailyHRVChartProvider>
        <ActivityGraph graphData={hrvChartData} name="HRV" color="#F85884" info={HRVInfo} />
      </DailyHRVChartProvider>
      <DailyActiveCaloriesProvider>
        <ActivityGraph
          graphData={activeCaloriesChartData}
          name="Active Calories"
          color="#FF8066"
          info={ActiveCaloriesInfo}
        />
      </DailyActiveCaloriesProvider>
    </div>
  )
}

export default DailySleepAndStepsCard
