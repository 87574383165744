import SectionContainer from "@features/Insight/components/atom/SectionContainer"

import ConversationMessage, { ConversationEntry } from "./ConversationMessage"

interface ConversationListProps {
  conversation: ConversationEntry[]
}

const ConversationList: React.FC<ConversationListProps> = ({ conversation }) => {
  // Sort messages by start time
  const sortedMessages = conversation.slice().sort((a, b) => a.start_time - b.start_time)

  // Merge consecutive messages with the same role
  const mergedMessages = sortedMessages.reduce((acc: ConversationEntry[], msg) => {
    if (acc.length === 0) {
      acc.push({ ...msg })
      return acc
    }
    const lastMsg = acc[acc.length - 1]
    if (lastMsg.role === msg.role) {
      lastMsg.text = `${lastMsg.text} ${msg.text}`
      lastMsg.end_time = msg.end_time
    } else {
      acc.push({ ...msg })
    }
    return acc
  }, [])

  return (
    <SectionContainer title="Conversation">
      <div className="space-y-4 bg-white p-6 pt-8 rounded-lg border">
        {mergedMessages.map((msg, idx) => (
          <div key={idx}>
            <ConversationMessage msg={msg} />
          </div>
        ))}
      </div>
    </SectionContainer>
  )
}

export default ConversationList
