// import TranscriptSearch from "./TranscriptSearch.tsx" # TODO 2025-03-27 Add back after RAG is ready
import TranscriptSection from "./TranscriptSection.tsx"

const TranscriptPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Transcript</h3>
      </div>
      {/* <TranscriptSearch /> */}
      <TranscriptSection />
    </div>
  )
}

export default TranscriptPage
