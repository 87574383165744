import { faChartSimple } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row } from "@tanstack/react-table"

interface SeeInsightReportActionProps {
  row: Row<ListUser>
}

const SeeInsightReportAction: React.FC<SeeInsightReportActionProps> = ({ row }) => {
  const user = row.original
  const overviewUrl = `/insight/${user.user_id}`

  const tooltipStyle = `
    absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 
    group-hover:opacity-100 transition-opacity duration-300 pointer-events-none
  `
  const tooltipTextStyle = "bg-black text-white rounded-full text-nowrap px-3 py-1 text-sm"
  const iconStyle = "text-lg text-neutral-300 hover:text-neutral-950 transition w-6"

  return (
    <div className="relative group inline-block">
      {/* button */}
      <button onClick={() => (window.location.href = overviewUrl)} className={iconStyle}>
        <FontAwesomeIcon icon={faChartSimple} />
      </button>
      {/* tooltip */}
      <div className={tooltipStyle}>
        <div className={tooltipTextStyle}>Insight Report</div>
      </div>
    </div>
  )
}

export default SeeInsightReportAction
