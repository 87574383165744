import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

import Button from "@/components/Buttons/Button.tsx"

import Card from "@components/Cards/Card.tsx"

import useClientOverviewStore from "@features/Clients/stores/ClientOverviewStores.ts"

import InsightReportAssignmentCard from "./InsightReportAssignmentCard"

const SurveysProgressCard: React.FC<CardTypeProps> = (CardProps) => {
  const assignments: ClientAssignment[] = structuredClone(useClientOverviewStore((state) => state.assignments))
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [sortKey, setSortKey] = useState<keyof ClientAssignment>("date_start")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")

  const itemsPerPage = 7

  const sortedAssignments = [...assignments].sort((a, b) => {
    const dateA = a[sortKey] ? new Date(a[sortKey] as string) : new Date(0)
    const dateB = b[sortKey] ? new Date(b[sortKey] as string) : new Date(0)
    return sortOrder === "asc" ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime()
  })

  const totalPages = Math.ceil(sortedAssignments.length / itemsPerPage)
  const currentAssignments = sortedAssignments.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)

  const goToNextPage = () => setCurrentPage((prev) => (prev + 1 < totalPages ? prev + 1 : prev))
  const goToPreviousPage = () => setCurrentPage((prev) => (prev > 0 ? prev - 1 : 0))

  const handleSortChange = (key: keyof ClientAssignment) => {
    setSortKey(key)
    setCurrentPage(0)
  }

  const toggleSortOrder = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"))
    setCurrentPage(0)
  }

  if (!CardProps || assignments.length < 1) return <div>Card cannot be displayed.</div>

  return (
    <Card {...CardProps} additionalClasses="border" hasShadow={false}>
      <div className="flex justify-between mb-3">
        <div className="flex gap-6">
          <div className="text-sm text-neutral-400 mt-1.5">Sort By</div>
          <select onChange={(e) => handleSortChange(e.target.value as keyof ClientAssignment)} value={sortKey}>
            <option value="date_start">Start Date</option>
            <option value="date_end">End Date</option>
            <option value="completed_at">Completion Date</option>
          </select>
          <button onClick={toggleSortOrder}>{sortOrder === "asc" ? "Ascending" : "Descending"}</button>
        </div>

        <div className="font-bold -mt-4 pt-0">
          <Button size="small" className="px-4" onClick={goToPreviousPage} disabled={currentPage <= 0}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button size="small" className="px-4" onClick={goToNextPage} disabled={currentPage >= totalPages - 1}>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </div>

      {currentAssignments.map((assignment, index) => (
        <InsightReportAssignmentCard key={index} {...assignment} />
      ))}
    </Card>
  )
}

export default SurveysProgressCard
