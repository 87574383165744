import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useStarredChartsStore from "../store/StarredChartsStore"

const StarredChartsProvider = ({ children }: { children: ReactNode }) => {
  const setStarredCharts = useStarredChartsStore((state) => state.setStarredCharts)
  const { clientId } = useParams()

  const {
    data: starredChartsData,
    isSuccess,
    isError,
    isLoading,
  } = useQuery<string[], Error>({
    queryKey: ["starredChartsData", clientId],
    enabled: !!clientId,
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/starred-charts/${clientId}`
      const response = await api.get<StarredChartsResponse>(endpoint)
      if (response.status === 200) return response.data.starred_charts
      throw new Error("Fetching starred charts failed")
    },
  })

  useEffect(() => {
    if (isSuccess && starredChartsData) {
      // Update the store with a new set of starred charts.
      setStarredCharts(new Set(starredChartsData))
    }
  }, [starredChartsData, isSuccess, setStarredCharts])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError)
    return (
      <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load starred charts at this time</div>
    )

  return <>{children}</>
}

export default StarredChartsProvider
