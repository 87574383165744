import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

interface BackButtonProps {
  className?: string
}

const BackButton: React.FC<BackButtonProps> = ({ className = "" }) => {
  const navigate = useNavigate()

  return (
    <button
      onClick={() => navigate(-1)}
      className={`flex items-center mr-2 text-neutral-300 hover:text-black hover:scale-125
        transition ${className}`}
    >
      <FontAwesomeIcon icon={faChevronLeft} className="h-12 w-2" />
    </button>
  )
}

export default BackButton
