import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { error, success } from "@/utilities/logger"

interface ProfileInfoItemProps {
  value: string
  copyable?: boolean
  tooltip?: string
}

const ProfileInfoItem: React.FC<ProfileInfoItemProps> = ({ value, copyable = false, tooltip }) => {
  const borderStyle = tooltip ? "border-b-2" : ""

  const handleCopy = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        success("Copied")
      })
      .catch(() => {
        error("Failed to copy")
      })
  }

  return (
    <div className={`relative group inline-block hover:${borderStyle} border-neutral-100 select-none`}>
      <div className="pt-1 no-first:ml-2 flex flex-row text-neutral-500 text-sm items-center">
        <span>{value}</span>
        {copyable && value != "..." && (
          <button
            onClick={handleCopy}
            className="ml-1 text-neutral-300 focus:outline-none text-xs hover:text-neutral-500 transition"
            title="Copy to clipboard"
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
        )}
      </div>
      {tooltip && (
        <div className="absolute left-1/2 transform -translate-x-1/2 translate-y-1/4 hidden group-hover:block">
          <div className="text-neutral-300 text-xs rounded px-2">
            <span
              className="transform translate-y-1/5 select-none
            "
            >
              {tooltip}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileInfoItem
