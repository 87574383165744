import ClientsProvider from "@/providers/ClientsProvider.tsx"

import Card from "@components/Cards/Card"

import ClientOverviewContainer from "./ClientOverviewContainer"
import AssignedSurveysCard from "./Components/assignment/AssignedSurveysCard"
import SurveysProgressCard from "./Components/assignment/SurveysProgressCard"
import AssignedSurveysProvider from "./providers/AssignedSurveysProvider"

const ClientAssignmentPage = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Assignments</h3>

        <ClientOverviewContainer title="Assigned Surveys" description="view assigned surveys">
          <Card additionalClasses="border w-full shadow-none">
            <AssignedSurveysProvider>
              <AssignedSurveysCard />
            </AssignedSurveysProvider>
          </Card>
        </ClientOverviewContainer>
        <ClientOverviewContainer title="Surveys & Responses" description="view all assigned surveys and responses">
          <ClientsProvider>
            <SurveysProgressCard />
          </ClientsProvider>
        </ClientOverviewContainer>
      </div>
    </div>
  )
}

export default ClientAssignmentPage
