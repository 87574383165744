import ChatInput from "./ChatInput"
import ChatMessages from "./ChatMessages"
import SessionsList from "./SessionsList"
import ChatProvider from "./providers/ChatProvider"

const ChatPage: React.FC = () => {
  return (
    <ChatProvider>
      <div className="flex w-full h-[calc(100vh-10rem)]">
        {/* Sidebar: Sessions list */}
        <SessionsList />
        {/* Main chat area */}
        <div className="flex flex-col flex-1 items-center w-full h-full">
          {/* Wrap messages and input in a flex container */}
          <div className="w-full ml-8 flex flex-col h-full space-y-4">
            {/* Messages expand to fill available space */}
            <div className="flex-1 overflow-y-auto">
              <ChatMessages />
            </div>
            {/* ChatInput stays at the bottom */}
            <div>
              <ChatInput />
            </div>
          </div>
        </div>
      </div>
    </ChatProvider>
  )
}

export default ChatPage
