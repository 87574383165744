import { QueryClient } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { create } from "zustand"

import api from "@utilities/api"

interface ChatStore {
  messages: ChatMessage[]
  addMessage: (message: ChatMessage) => void
  removeMessage: (id: string) => void
  clearMessages: () => void
  editingMessage: string
  setEditingMessage: (text: string) => void
  clearEditingMessage: () => void
  current_chat_session_id: string | null
  setCurretnChatSessionId: (id: string | null) => void
  sessions: ChatSession[]
  setSessions: (sessions: ChatSession[]) => void
  currentMessages: ChatMessage[]
  setCurrentMessages: (messages: ChatMessage[]) => void
  sendMessageToVertexAI: (message: string, queryClient: QueryClient) => Promise<string>
  // New properties for loading chat history:
  loadingChatHistory: boolean
  setLoadingChatHistory: (loading: boolean) => void
  loadChatHistory: (session_id: string) => Promise<void>
}

const useChatStore = create<ChatStore>((set, get) => ({
  messages: [],
  addMessage: (message) => set((state) => ({ messages: [...state.messages, message] })),
  removeMessage: (id) => set((state) => ({ messages: state.messages.filter((msg) => msg.id !== id) })),
  clearMessages: () => set({ messages: [] }),
  editingMessage: "",
  setEditingMessage: (text) => set(() => ({ editingMessage: text })),
  clearEditingMessage: () => set(() => ({ editingMessage: "" })),
  current_chat_session_id: null,
  setCurretnChatSessionId: (id) => set(() => ({ current_chat_session_id: id })),
  sessions: [],
  setSessions: (sessions) => set(() => ({ sessions })),
  currentMessages: [],
  setCurrentMessages: (messages) => set(() => ({ currentMessages: messages })),
  sendMessageToVertexAI: async (message: string, queryClient: QueryClient): Promise<string> => {
    const chat_session_id = get().current_chat_session_id
    try {
      const result: AxiosResponse<ChatResponse> = await api.post<ChatResponse>("llm/chat", {
        message,
        chat_session_id,
      })

      if (result.status === 200) {
        if (result.data.response.chat_session_id) {
          // If there was no previous session, reload sessions.
          if (chat_session_id === null || get().currentMessages.length % 10 === 0) {
            void queryClient.invalidateQueries({ queryKey: ["chatSessions"] })
          }
          // Set the new chat session id.
          get().setCurretnChatSessionId(result.data.response.chat_session_id)
        }
        return result.data.response.text
      } else {
        throw new Error("Failed to get chat response from backend")
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message || "Error sending chat message")
      } else {
        throw new Error("Error sending chat message")
      }
    }
  },
  // New state and function for loading chat history
  loadingChatHistory: false,
  setLoadingChatHistory: (loading: boolean) => set(() => ({ loadingChatHistory: loading })),
  loadChatHistory: async (session_id: string) => {
    // Set current session, clear messages and show loading indicator
    set(() => ({ loadingChatHistory: true, current_chat_session_id: session_id, messages: [] }))
    try {
      const response = await api.get<{ messages: ChatMessage[] }>(`llm/chat/history/${session_id}`)
      if (response.status === 200) {
        set(() => ({ messages: response.data.messages }))
      } else {
        throw new Error("Failed to load chat history")
      }
    } catch (error) {
      console.error(error)
    } finally {
      set(() => ({ loadingChatHistory: false }))
    }
  },
}))

export default useChatStore
