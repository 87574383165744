import { useParams } from "react-router-dom"

import ClinicianInspectPolicies from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ClinicianInspectPolicies.tsx"

import ClinicianRelationshipsProvider from "@features/Home/providers/ClinicianRelationshipsProvider.tsx"

import ClientOverviewContainer from "./ClientOverviewContainer"

const ClientPermissionsPage = () => {
  const { clientId } = useParams()

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Permissions</h3>
        <ClientOverviewContainer title="Client’s Privacy Settings & Shared Data">
          <ClinicianRelationshipsProvider clientUserId={clientId}>
            <div className="bg-white border rounded-xl py-3 px-6">
              <ClinicianInspectPolicies />
            </div>
          </ClinicianRelationshipsProvider>
        </ClientOverviewContainer>
      </div>
    </div>
  )
}

export default ClientPermissionsPage
