import SectionContainer from "../Insight/components/atom/SectionContainer"
import RelationshipCard from "./RelationshipCard"
import RelationshipMoodGraph from "./graph/RelationshipMoodGraph"
import RelationshipMoodProvider from "./provider/RelationshipMoodProvider"
import useRelationshipMoodStore from "./store/RelationshipMoodStore"
import useTranscriptDetailStore from "./store/TranscriptDetailStore"

const RelationshipSection: React.FC = () => {
  const currentTranscriptDetail = useTranscriptDetailStore((state) => state.currentTranscriptDetail)
  const currentActiveRelationshipRole = useRelationshipMoodStore((state) => state.currentActiveRelationshipRole)
  const setCurrentActiveRelationshipRole = useRelationshipMoodStore((state) => state.setCurrentActiveRelationshipRole)

  if (!currentTranscriptDetail?.relationship_metadata) {
    return (
      <SectionContainer
        title="Relationships (experimental feature)"
        description="View the relationships mentioned in the transcript"
      >
        <div className="flex flex-row space-x-4 text-center w-full items-center justify-center text-neutral-300 text-xs">
          No relationship data available
        </div>
      </SectionContainer>
    )
  }

  return (
    <SectionContainer
      title="Relationships (experimental feature)"
      description="View the relationships mentioned in the transcript"
    >
      <div className="flex flex-row space-x-4">
        <RelationshipMoodProvider>
          <div className="bg-white p-6 pt-8 rounded-lg border h-96 w-96 flex-shrink-0">
            <RelationshipMoodGraph />
          </div>
        </RelationshipMoodProvider>

        <div className="space-y-4 w-full">
          {currentTranscriptDetail?.relationship_metadata.map((relationship) => (
            <RelationshipCard
              key={relationship.relationship_person}
              relationship={relationship}
              activeRole={currentActiveRelationshipRole}
              onClick={setCurrentActiveRelationshipRole}
            />
          ))}
        </div>
      </div>
    </SectionContainer>
  )
}
export default RelationshipSection
