import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useActiveCaloriesSummaryStore from "../../summary/store/ActiveCaloriesSummaryStore.ts"

const DailyActiveCaloriesProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentActiveCaloriesData = useActiveCaloriesSummaryStore((state) => state.setCurrentActiveCaloriesResponse)
  const currentOffsetDays = useActiveCaloriesSummaryStore((state) => state.currentOffsetDays)
  const currentLimitDays = useActiveCaloriesSummaryStore((state) => state.currentLimitDays)

  const { clientId } = useParams()

  const {
    data: activeCaloriesDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["dailyActiveCaloriesData", clientId, currentOffsetDays, currentLimitDays],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/summary/active-calories/${clientId}?offset_days=${currentOffsetDays}&limit_days=${currentLimitDays}`
      const { data, status } = await api.get<ActiveCaloriesSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching active calories data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && activeCaloriesDataResponse) {
      setCurrentActiveCaloriesData(activeCaloriesDataResponse)
    }
  }, [isSuccess, activeCaloriesDataResponse, setCurrentActiveCaloriesData])

  if (isLoading) {
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="my-6 w-full text-center text-sm text-neutral-200">
        Could not load active calories data at this time
      </div>
    )
  }

  return <>{children}</>
}

export default DailyActiveCaloriesProvider
