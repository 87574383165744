import { useQuery } from "@tanstack/react-query"
import { FC, ReactNode, useEffect } from "react"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import useChatStore from "@features/LLM/stores/ChatStore"

import api from "@utilities/api"

interface ChatProviderProps {
  children: ReactNode
}

export const ChatProvider: FC<ChatProviderProps> = ({ children }) => {
  const setSessions = useChatStore((state) => state.setSessions)

  const {
    data: sessions,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["chatSessions"],
    queryFn: async () => {
      const response = await api.get<{ chat_sessions: ChatSession[] }>("llm/chats")
      if (response.status === 200) {
        return response.data.chat_sessions
      } else {
        throw new Error("Failed to fetch chat sessions")
      }
    },
  })

  useEffect(() => {
    if (sessions) {
      setSessions(sessions)
    }
  }, [sessions, setSessions])

  if (isLoading)
    return (
      <div className="mt-6">
        <LoadingSpinner size="2x" />
      </div>
    )
  if (isError) return <div className="mt-6 text-neutral-300">Failed to load chat sessions</div>

  return <>{children}</>
}

export default ChatProvider
