import { create } from "zustand"

interface LocationInsightStore {
  currentLocationResponse: LocationInsightResponse | null
  setCurrentLocationResponse: (response: LocationInsightResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
  resetDateRange: () => void
}

const useLocationInsightStore = create<LocationInsightStore>((set) => ({
  currentLocationResponse: null,
  setCurrentLocationResponse: (currentLocationResponse) => set({ currentLocationResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
  resetDateRange: () => set({ currentOffsetDays: 0, currentLimitDays: 30 }),
}))

export default useLocationInsightStore
