import useSummaryHeaderStore from "../summary/store/SummaryHeaderStore"
import ProfileSummary from "./ProfileSummary"

interface ClientProfileHeaderProps {
  headerType: "insight-report" | "client-overview"
}

const ClientProfileHeader: React.FC<ClientProfileHeaderProps> = ({ headerType }) => {
  const { currentSummaryHeaderResponse } = useSummaryHeaderStore()

  if (!currentSummaryHeaderResponse) {
    return null // Render nothing or a loading state if the data is not yet available
  }

  const { profile_data } = currentSummaryHeaderResponse
  const firstName = profile_data?.first_name || ""
  const lastName = profile_data?.last_name || ""
  const age = profile_data?.age?.toString() || "..."
  const dob = profile_data?.birthdate || "..."
  const gender = profile_data?.sex || "..."
  const phone = profile_data?.phone || "..."
  const email = profile_data?.email || "..."

  const rowMarginBottom = "mb-3"

  const showInsightReportButton = headerType === "client-overview" ? true : false
  const showClientOverviewButton = headerType === "insight-report" ? true : false

  return (
    <div className={`w-full ${rowMarginBottom} justify-center flex`}>
      <ProfileSummary
        firstName={firstName}
        lastName={lastName}
        age={age}
        dob={dob}
        gender={gender}
        phone={phone}
        email={email}
        showInsightReportButton={showInsightReportButton}
        showClientOverviewButton={showClientOverviewButton}
      />
    </div>
  )
}

export default ClientProfileHeader
