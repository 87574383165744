import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

interface MarkdownRendererProps {
  content: string
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ ...props }) => <h1 className="text-4xl font-bold mt-8 mb-4" {...props} />,
        h2: ({ ...props }) => <h2 className="text-3xl font-semibold mt-6 mb-3" {...props} />,
        h3: ({ ...props }) => <h3 className="text-2xl font-semibold mt-4 mb-2" {...props} />,
        h4: ({ ...props }) => <h4 className="text-xl font-semibold mt-3 mb-1" {...props} />,
        h5: ({ ...props }) => <h5 className="text-lg font-semibold mt-2 mb-1" {...props} />,
        h6: ({ ...props }) => <h6 className="text-base font-semibold mt-2 mb-1" {...props} />,
        p: ({ ...props }) => <p className="text-base" {...props} />,
        a: ({ ...props }) => (
          <a className="text-white text-sm bg-neutral-500 rounded-xl px-2 hover:underline bg-opacity-50" {...props} />
        ),
        pre: ({ ...props }) => (
          <pre
            className="whitespace-pre-wrap my-2 bg-neutral-200 p-4 bg-opacity-25 rounded-lg break-words"
            {...props}
          />
        ),
        code: ({ ...props }) => <code className="bg-neutral-100 px-1 text-green-700 rounded" {...props} />,
        span: ({ ...props }) => <span className="break-words" {...props} />,
        li: ({ ...props }) => <li className="list-disc ml-6 my-2 text-base leading-relaxed" {...props} />,
        ul: ({ ...props }) => (
          <ul className="list-disc ml-6 my-2 text-base leading-relaxed rounded-xl p-3 hover:bg-white" {...props} />
        ),
        table: ({ ...props }) => (
          <div className="overflow-x-auto my-4">
            <table className="w-full text-sm text-left border-collapse" {...props} />
          </div>
        ),
        thead: ({ ...props }) => <thead className="text-xs uppercase bg-neutral-200 bg-opacity-25" {...props} />,
        tbody: ({ ...props }) => <tbody {...props} />,
        tr: ({ ...props }) => (
          <tr
            className="border-b border-neutral-200 hover:bg-white transition ease-in-out hover:bg-opacity-50"
            {...props}
          />
        ),
        th: ({ ...props }) => <th className="px-6 py-3 border border-neutral-200" {...props} />,
        td: ({ ...props }) => <td className="px-6 py-3 border border-neutral-200" {...props} />,
      }}
    >
      {content}
    </Markdown>
  )
}

export default MarkdownRenderer
