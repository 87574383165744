import * as d3 from "d3"

import { brightenColor } from "./utils"

const SCORE_COLORS = {
  low: "#09de77",
  medium: "#ffab20",
  high: "#ff3e26",
}

class SurveyScoresGraphD3 {
  constructor(element, width, height, data, color = "#666666") {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 25, right: 15, bottom: 5, left: 15 }
    this.svg = null
    this.color = color

    this.createChart()
  }

  createChart() {
    // Remove any existing svg
    d3.select(this.element).select("svg").remove()

    const showAllDots = true
    const svg = d3
      .select(this.element)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .append("g")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    this.svg = svg

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    const parseDate = d3.timeParse("%Y-%m-%d")

    const chartData = this.data.scores.map((d) => ({
      date: parseDate(d.date),
      score: d.score,
    }))

    // X scale
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(chartData, (d) => d.date))
      .range([0, innerWidth])

    // Y scale
    const yScale = d3.scaleLinear().domain([this.data.min_score, this.data.max_score]).nice().range([innerHeight, 0])

    // line generator
    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.score))
      .curve(d3.curveMonotoneX)

    // Gradient with stops and trasition for each range
    const gradientId = "line-gradient-survey-ranges"

    const offsetForScore = (score) => (innerHeight - yScale(score)) / innerHeight

    const clampOffset = (val) => Math.min(Math.max(val, 0), 1)

    const offsetMin = clampOffset(offsetForScore(this.data.min_score))
    const offsetMedium = clampOffset(offsetForScore(this.data.medium))
    const offsetHigh = clampOffset(offsetForScore(this.data.high))
    const offsetMax = clampOffset(offsetForScore(this.data.max_score))

    const transitionDelta = 0.02 // for smooth transition between color ranges

    const offsetLowToMediumStart = clampOffset(offsetMedium - transitionDelta)
    const offsetLowToMediumEnd = clampOffset(offsetMedium + transitionDelta)

    const offsetMediumToHighStart = clampOffset(offsetHigh - transitionDelta)
    const offsetMediumToHighEnd = clampOffset(offsetHigh + transitionDelta)

    const defs = svg.append("defs")
    const gradient = defs
      .append("linearGradient")
      .attr("id", gradientId)
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", innerHeight)
      .attr("x2", 0)
      .attr("y2", 0)

    // Define stops in ascending offset order:
    gradient.append("stop").attr("offset", offsetMin).attr("stop-color", SCORE_COLORS.low)

    gradient.append("stop").attr("offset", offsetLowToMediumStart).attr("stop-color", SCORE_COLORS.low)

    gradient.append("stop").attr("offset", offsetLowToMediumEnd).attr("stop-color", SCORE_COLORS.medium)

    gradient.append("stop").attr("offset", offsetMediumToHighStart).attr("stop-color", SCORE_COLORS.medium)

    gradient.append("stop").attr("offset", offsetMediumToHighEnd).attr("stop-color", SCORE_COLORS.high)

    gradient.append("stop").attr("offset", offsetMax).attr("stop-color", SCORE_COLORS.high)

    // Reference lines for low, medium, high areas
    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", yScale(this.data.min_score))
      .attr("x2", innerWidth)
      .attr("y2", yScale(this.data.min_score))
      .attr("stroke", "#cccccc")
      .attr("stroke-dasharray", "2,2")
      .attr("stroke-width", 1)

    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", yScale(this.data.medium))
      .attr("x2", innerWidth)
      .attr("y2", yScale(this.data.medium))
      .attr("stroke", "#cccccc")
      .attr("stroke-dasharray", "2,2")
      .attr("stroke-width", 1)

    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", yScale(this.data.high))
      .attr("x2", innerWidth)
      .attr("y2", yScale(this.data.high))
      .attr("stroke", "#cccccc")
      .attr("stroke-dasharray", "2,2")
      .attr("stroke-width", 1)

    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", yScale(this.data.max_score))
      .attr("x2", innerWidth)
      .attr("y2", yScale(this.data.max_score))
      .attr("stroke", "#cccccc")
      .attr("stroke-dasharray", "2,2")
      .attr("stroke-width", 1)

    // Main line: using gradient stroke
    svg
      .append("path")
      .datum(chartData)
      .attr("fill", "none")
      .attr("stroke", `url(#${gradientId})`)
      .attr("stroke-width", 4)
      .attr("d", line)

    // Focus circle for hover
    const focusCircle = svg
      .append("circle")
      .attr("r", 6)
      .style("fill", "none")
      .attr("stroke", "#333")
      .attr("stroke-width", 6)
      .style("opacity", 0)

    // Focus line for hover
    const focusLine = svg
      .append("line")
      .attr("class", "focusLine")
      .attr("stroke", "#000")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3")
      .style("opacity", 0)

    // Show circles on each data point
    if (showAllDots) {
      const dots = svg
        .selectAll(".dot-group")
        .data(chartData)
        .enter()
        .append("g")
        .attr("class", "dot-group")
        .attr("transform", (d) => `translate(${xScale(d.date)}, ${yScale(d.score)})`)

      // Circle for each dot
      dots
        .append("circle")
        .attr("r", 6)
        .attr("fill", (d) => {
          if (d.score < this.data.medium) {
            return SCORE_COLORS.low
          } else if (d.score < this.data.high) {
            return SCORE_COLORS.medium
          } else {
            return SCORE_COLORS.high
          }
        })

      // Label on top of each dot
      dots
        .append("text")
        .attr("text-anchor", "middle")
        .attr("dy", "-12")
        .attr("font-size", "12px")
        .attr("fill", "#333")
        .text((d) => d.score.toFixed(2))
    }

    // Tooltip setup
    const tooltip = d3
      .select(this.element)
      .append("div")
      .attr("class", "survey-score-tooltip")
      .style("position", "absolute")
      .style("padding", "10px 20px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("color", "#fff")
      .style("font-size", "12px")
      .style("pointer-events", "none")
      .style("white-space", "nowrap")
      .style("opacity", 0)

    // Interaction overlay
    svg
      .append("rect")
      .attr("class", "overlay")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", (event) =>
        this.handleMouseMove(event, chartData, xScale, yScale, tooltip, focusLine, focusCircle),
      )
      .on("mouseout", () => {
        tooltip.style("opacity", 0)
        focusLine.style("opacity", 0)
        focusCircle.style("opacity", 0)
      })
  }

  handleMouseMove(event, data, xScale, yScale, tooltip, focusLine, focusCircle) {
    d3.selectAll(".survey-score-tooltip").style("opacity", 0)
    const [xPos] = d3.pointer(event)
    const xDate = xScale.invert(xPos)
    const bisectDate = d3.bisector((d) => d.date).left
    let index = bisectDate(data, xDate, 1)

    if (index >= data.length) index = data.length - 1
    const leftItem = data[index - 1]
    const rightItem = data[index]

    let d = data[0]
    if (leftItem && rightItem) {
      d = xDate - leftItem.date > rightItem.date - xDate ? rightItem : leftItem
    } else if (leftItem) {
      d = leftItem
    } else if (rightItem) {
      d = rightItem
    }
    if (!d) return

    // Format the date for tooltip
    const formatDate = d3.timeFormat("%m/%d/%Y")
    const scoreValue = d.score.toFixed(2)

    // range label
    let rangeLabel = "High"
    if (d.score < this.data.medium) {
      rangeLabel = "Low"
    } else if (d.score < this.data.high) {
      rangeLabel = "Medium"
    }

    tooltip
      .html(
        `<span>Completed at</span><br/>
         <span style="color: ${brightenColor(this.color, 0.8)};">${formatDate(d.date)}</span> <br/>
         <strong>${scoreValue} ${rangeLabel}</strong>`,
      )
      .style("left", `${event.pageX - 40}px`)
      .style("top", `${event.pageY - 100}px`)
      .style("opacity", 1)

    // Position the focus line
    focusLine
      .attr("x1", xScale(d.date))
      .attr("x2", xScale(d.date))
      .attr("y1", -this.margin.top)
      .attr("y2", this.height)
      .style("opacity", 0.2)

    // Position the focus circle
    focusCircle.attr("cx", xScale(d.date)).attr("cy", yScale(d.score)).style("opacity", 0.2)
  }
}

export default SurveyScoresGraphD3
