import { create } from "zustand"

interface MoodInsightStore {
  currentMoodResponse: InsightReportMoodData | null
  setCurrentMoodResponse: (response: InsightReportMoodData) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
  currentActiveMoodPanel: number
  setCurrentActiveMoodPanel: (activePanel: number) => void
  resetDateRange: () => void
}

const useMoodInsightStore = create<MoodInsightStore>((set) => ({
  currentMoodResponse: null,
  setCurrentMoodResponse: (currentMoodResponse) => set({ currentMoodResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 21,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
  currentActiveMoodPanel: 2,
  setCurrentActiveMoodPanel: (currentActiveMoodPanel) => set({ currentActiveMoodPanel }),
  resetDateRange: () => set({ currentOffsetDays: 0, currentLimitDays: 21, currentActiveMoodPanel: 2 }),
}))

export default useMoodInsightStore
