import * as d3 from "d3"

class RelationshipsGraphD3 {
  constructor(element, width, height, relationshipsData, showControls = false) {
    this.element = element
    this.width = width
    this.height = height
    this.relationshipsData = relationshipsData
    this.createChart()
    if (showControls) {
      this.addControls()
    }
  }

  createChart() {
    const width = this.width
    const height = this.height

    const { persons = [], relationship: graphData } = this.relationshipsData

    // Compute the unique types from graphData
    const types = Array.from(new Set(graphData.map((d) => d.type)))
    const nodesSet = new Set()
    graphData.forEach((d) => {
      nodesSet.add(d.source)
      nodesSet.add(d.target)
    })
    // Create nodes, marking those that are persons to render them larger
    const nodes = Array.from(nodesSet).map((id) => ({
      id,
      isPerson: persons.includes(id),
    }))
    // Copy links data
    const links = graphData.map((d) => ({ ...d }))

    // Create a color scale for the relationship types
    const gradientColors = types.map((_, i) => {
      const t = types.length === 1 ? 0.5 : i / (types.length - 1)
      return d3.interpolateRgb("#333333", "#ff3e26")(t)
    })
    this.color = d3.scaleOrdinal(types, gradientColors)

    // Create a force simulation for the nodes and links
    this.simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3.forceLink(links).id((d) => d.id),
      )
      .force("charge", d3.forceManyBody().strength(-400))
      .force("x", d3.forceX())
      .force("y", d3.forceY())

    // Create the SVG element using a centered viewBox
    this.svg = d3
      .select(this.element)
      .append("svg")
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("width", width)
      .attr("height", height)
      .attr("style", "max-width: 100%; height: auto; font: 12px sans-serif;")

    // Define markers for each relationship type so that links have arrows
    const defs = this.svg.append("defs")
    defs
      .selectAll("marker")
      .data(types)
      .join("marker")
      .attr("id", (d) => `arrow-${d}`)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 15)
      .attr("refY", -0.5)
      .attr("markerWidth", (d) => (d === "action" ? 8 : 12))
      .attr("markerHeight", (d) => (d === "action" ? 8 : 12))
      .attr("orient", "auto")
      .append("path")
      .attr("fill", this.color)
      .attr("d", "M0,-5L10,0L0,5")

    // Draw links as paths with markers
    this.link = this.svg
      .append("g")
      .attr("fill", "none")
      .attr("stroke-width", 1.5)
      .style("opacity", 0.6)
      .selectAll("path")
      .data(links)
      .join("path")
      .attr("stroke", (d) => this.color(d.type))
      .attr("stroke-width", (d) => (d.type === "action" ? 2 : 1))
      .attr("stroke-dasharray", (d) => (d.type === "manifest" ? "4,2" : null))
      .attr("marker-end", (d) => `url(${new URL(`#arrow-${d.type}`, location)})`)

    // Draw nodes as groups to include both circle and text label
    this.node = this.svg
      .append("g")
      .attr("fill", "currentColor")
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round")
      .selectAll("g")
      .data(nodes)
      .join("g")
      .call(this.drag(this.simulation))

    // Append circle for each node; larger if it represents a person
    this.node
      .append("circle")
      .attr("stroke", "white")
      .attr("stroke-width", 1.5)
      .style("opacity", 0.6)
      .attr("r", (d) => (d.isPerson ? 8 : 4))

    // Append a text label for each node (splitting at ":" to remove extra details)
    this.node
      .append("text")
      .attr("x", 8)
      .attr("y", "0.31em")
      .text((d) => d.id.split(":")[0])
      .clone(true)
      .lower()
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-width", 3)
      .style("opacity", 0.6)

    // Function to compute curved paths for links
    const linkArc = (d) => {
      const dx = d.target.x - d.source.x
      const dy = d.target.y - d.source.y
      const dr = Math.sqrt(dx * dx + dy * dy) * 3
      return `M${d.source.x},${d.source.y}A${dr},${dr} 0 0,1 ${d.target.x},${d.target.y}`
    }

    // Update node and link positions on every simulation tick
    this.simulation.on("tick", () => {
      this.link.attr("d", linkArc)
      this.node.attr("transform", (d) => `translate(${d.x},${d.y})`)
    })
  }

  // Drag behavior for nodes
  drag(simulation) {
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart()
      d.fx = d.x
      d.fy = d.y
    }
    function dragged(event, d) {
      d.fx = event.x
      d.fy = event.y
    }
    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0)
      d.fx = null
      d.fy = null
    }
    return d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended)
  }

  // Optionally add controls such as a restart button
  addControls() {
    const controlDiv = d3.select(this.element).append("div").attr("class", "controls").style("margin-top", "10px")

    controlDiv
      .append("button")
      .text("Restart Simulation")
      .on("click", () => {
        this.simulation.alpha(1).restart()
      })
  }
}

export default RelationshipsGraphD3
