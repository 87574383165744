import ClientsProvider from "@/providers/ClientsProvider.tsx"

import ClinicianClients from "@features/Home/components/ClinicianHome/ClinicianClients.tsx"

const ProviderHome = () => {
  return (
    <ClientsProvider>
      {/* Clients Table */}
      <ClinicianClients />
    </ClientsProvider>
  )
}

export default ProviderHome
