const ClientRelationshipCard = ({
  first_name,
  last_name,
  relationship_accepted_at,
  created_at,
  relationship_type,
}: ClientRelationship) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }
  const acceptedAt = formatDate(relationship_accepted_at)
  const createdAt = formatDate(created_at)

  return (
    <div className="flex flex-row not-last:mb-4 p-4 not-last:border-b bg-white rounded-xl border">
      <div className="flex">
        <div className="flex flex-col w-32 h-10 rounded-full font-bold text-neutral-400 p-2 mr-8 text-left ">
          {relationship_type.toLocaleUpperCase()}
        </div>
      </div>
      <div className="flex flex-col">
        <div className={"font-bold text-black text-lg mb-2"}>
          {first_name} {last_name}
        </div>
        <div className="text-neutral-400 flex flex-row">
          <div className="w-28">Accepted at:</div>
          <div>{acceptedAt}</div>
        </div>
        <div className="text-neutral-400 flex flex-row">
          <div className="w-28">Created at:</div>
          <div>{createdAt}</div>
        </div>
      </div>
    </div>
  )
}

export default ClientRelationshipCard
