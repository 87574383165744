import { useEffect, useRef } from "react"

import SectionContainer from "@/features/Insight/components/atom/SectionContainer"
import TranscriptMoodD3 from "@/features/Transcript/graph/D3Files/TranscriptMoodD3.js"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import useTranscriptMoodStore from "../store/TranscriptMoodStore"

interface TranscriptMoodProps {
  startFromIndex?: number
  enableAutoPlay?: boolean
}

const TranscriptMood = ({ startFromIndex = 0, enableAutoPlay = false }: TranscriptMoodProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const currentGraphData = useTranscriptMoodStore((state) => state.transcriptMoods)
  const validData = currentGraphData
  const transitionDuration = 3000
  const showControl = false

  useEffect(() => {
    if (isValidSize && validData) {
      new TranscriptMoodD3(
        chartArea.current,
        containerSize.width,
        containerSize.height,
        currentGraphData,
        transitionDuration,
        enableAutoPlay,
        startFromIndex,
        showControl,
      )
    }
  }, [
    containerSize,
    currentGraphData,
    isValidSize,
    validData,
    transitionDuration,
    enableAutoPlay,
    startFromIndex,
    showControl,
  ])

  return (
    <SectionContainer title="Mood Analysis (experimental feature)" description="Mood analysis based on the transcript">
      <div className="flex w-full rounded-lg bg-white border justify-center">
        <div className="w-96 h-96 p-4">
          <div className="w-full h-full overflow-hidden" ref={containerRef}>
            <div className="chart-area flex-1 w-full h-full overflow-hidden" ref={chartArea}></div>
          </div>
        </div>
      </div>
    </SectionContainer>
  )
}

export default TranscriptMood
