import LoadingSpinner from "@/components/Loading/LoadingSpinner"
import useClientRelationshipsStore from "@/features/CareCircleNew/stores/ClientRelationshipsStore"

import ClientRelationshipCard from "../Atoms/ClientRelationshipCard"

const ClientRelationshipsOverview = () => {
  const relationships = useClientRelationshipsStore((state) => state.clientRelationshipsData)
  return (
    <>
      {(!relationships || relationships.length === 0) && (
        <div className="w-full  flex flex-col items-center justify-center">
          <div className="h-8 w-8">
            <LoadingSpinner />
          </div>
        </div>
      )}
      {relationships.map((relationship, index) => (
        <ClientRelationshipCard key={index} {...relationship} />
      ))}
    </>
  )
}
export default ClientRelationshipsOverview
