import { useState } from "react"

import { Tabs, TabsContent } from "@/shadcn-components/ui/tabs"

import InsightReportTabs from "./InsightReportTabs"
import ActivityPage from "./components/activity/ActivityPage"
import HabitPage from "./components/habit/HabitPage"
import ClientProfileHeader from "./components/header/ClientProfileHeader"
import ProfileBar from "./components/header/ProfileBar"
import LocationPage from "./components/location/LocationPage"
import MoodPage from "./components/mood/MoodPage"
import StarredChartsPage from "./components/starred/StarredChartsPage"
import SummaryPage from "./components/summary/SummaryPage"
import SummaryHeaderProvider from "./components/summary/provider/SummaryHeaderProvider"
import SurveyPage from "./components/survey/SurveyPage"
import TranscriptPage from "./components/transcript/TranscriptPage"

const InsightReportPage: React.FC = () => {
  const [, setActiveTab] = useState("summary")

  return (
    <div className="flex flex-col items-center pb-8 w-full">
      <SummaryHeaderProvider>
        <ProfileBar />
        <ClientProfileHeader headerType="insight-report" />
      </SummaryHeaderProvider>

      <Tabs defaultValue="summary" onValueChange={(value) => setActiveTab(value)} className="w-full">
        <div className="flex justify-center items-center">
          <InsightReportTabs />
        </div>

        <TabsContent value="summary">
          <SummaryPage />
        </TabsContent>

        <TabsContent value="activity">
          <ActivityPage />
        </TabsContent>

        <TabsContent value="location">
          <LocationPage />
        </TabsContent>

        <TabsContent value="surveys">
          <SurveyPage />
        </TabsContent>

        <TabsContent value="habits">
          <HabitPage />
        </TabsContent>

        <TabsContent value="mood">
          <MoodPage />
        </TabsContent>

        <TabsContent value="transcript">
          <TranscriptPage />
        </TabsContent>

        <TabsContent value="starred">
          <StarredChartsPage />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default InsightReportPage
