import { create } from "zustand"

interface DaylightSummaryStore {
  currentDaylightResponse: DaylightSummaryResponse | null
  setCurrentDaylightResponse: (response: DaylightSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
  resetDateRange: () => void
}

const useDaylightSummaryStore = create<DaylightSummaryStore>((set) => ({
  currentDaylightResponse: null,
  setCurrentDaylightResponse: (currentDaylightResponse) => set({ currentDaylightResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
  resetDateRange: () => set({ currentOffsetDays: 0, currentLimitDays: 30 }),
}))

export default useDaylightSummaryStore
