import ClientOverviewContainer from "./ClientOverviewContainer"
import ClientProfileDetailCard from "./Components/Organisms/ClientProfileDetailCard.tsx"

const ClientProfileDetailsPage = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Profile Details</h3>
        <ClientOverviewContainer title="Client Info">
          <ClientProfileDetailCard />
        </ClientOverviewContainer>
      </div>
    </div>
  )
}

export default ClientProfileDetailsPage
