interface ActionButtonsGroupProps {
  children: React.ReactNode
  gapClassName?: string
}

const ActionButtonsGroup: React.FC<ActionButtonsGroupProps> = ({ children, gapClassName = "space-x-4" }) => {
  return <div className={`flex items-center ${gapClassName}`}>{children}</div>
}

export default ActionButtonsGroup
