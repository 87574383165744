import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useTranscriptListStore from "../store/TranscriptListStore"

const TranscriptListProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentTranscriptList = useTranscriptListStore((state) => state.setCurrentTranscriptList)
  const { clientId } = useParams()

  const {
    data: transcriptListData,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["transcripts", clientId],
    enabled: !!clientId,
    retry: false,
    queryFn: async () => {
      const endpoint = `sessions/transcripts/${clientId}`
      const response = await api.get<TranscriptResponse>(endpoint)
      if (response.status === 200) return response.data.data
      throw new Error("Fetching transcript summaries failed")
    },
  })

  useEffect(() => {
    if (isSuccess && transcriptListData) {
      setCurrentTranscriptList(transcriptListData)
    }
  }, [transcriptListData, isSuccess, setCurrentTranscriptList])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError || !transcriptListData || transcriptListData.length === 0)
    return <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load transcript list</div>

  return <>{children}</>
}

export default TranscriptListProvider
