import { create } from "zustand"

interface HeartRateSummaryStore {
  currentHeartRateResponse: HeartRateSummaryResponse | null
  setCurrentHeartRateResponse: (response: HeartRateSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
  resetDateRange: () => void
}

const useHeartRateSummaryStore = create<HeartRateSummaryStore>((set) => ({
  currentHeartRateResponse: null,
  setCurrentHeartRateResponse: (currentHeartRateResponse) => set({ currentHeartRateResponse }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
  resetDateRange: () => set({ currentOffsetDays: 0, currentLimitDays: 30 }),
}))

export default useHeartRateSummaryStore
