import { faChartSimple, faMobileAlt, faPhone, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useParams } from "react-router-dom"

import IconButton from "@/components/Buttons/IconButton.tsx"
import Card from "@/components/Cards/Card.tsx"
import Modal from "@/components/Modals/Modal.tsx"
import ProfileInfoItem from "@/features/Insight/components/atom/ProfileInfoItem.tsx"
import BackButton from "@/features/Insight/components/header/BackButton.tsx"

import DeviceProvider from "../summary/provider/DeviceProvider"
import useDeviceStore from "../summary/store/DeviceStore"

interface ProfileSummaryProps {
  firstName?: string
  lastName?: string
  age?: string
  dob?: string
  gender?: string
  phone?: string
  email?: string
  emergencyContact?: string
  showInsightReportButton?: boolean
  showClientOverviewButton?: boolean
}

const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  firstName = "...",
  lastName = "...",
  age = "...",
  dob = "...",
  gender = "...",
  phone = "...",
  email = "...",
  emergencyContact = "...",
  showInsightReportButton = false,
  showClientOverviewButton = false,
}) => {
  const cardStyle = "relative border-neutral-200 w-full shadow-none p-0"
  const { clientId } = useParams()
  const clientOverviewPageRoute = `/pr/clients/${clientId}`
  const insightReportPageRoute = `/insight/${clientId}`

  const [isEmergencyModalOpen, setEmergencyModalOpen] = useState(false)
  const [isDeviceModalOpen, setDeviceModalOpen] = useState(false)

  const handleOpenEmergencyModal = () => setEmergencyModalOpen(true)
  const handleCloseEmergencyModal = () => setEmergencyModalOpen(false)

  const handleOpenDeviceModal = () => setDeviceModalOpen(true)
  const handleCloseDeviceModal = () => setDeviceModalOpen(false)

  const hardwareVersions = useDeviceStore((state) => state.currentDeviceResponse?.hardware_versions || [])

  return (
    <Card hasBorder={false} hasShadow={false} additionalClasses={cardStyle} padding="p-0">
      <div className="flex flex-row w-full justify-between select-none">
        <div className="flex flex-col h-12 justify-center items-center mr-3 ml-0.5">
          <BackButton />
        </div>

        <div className="w-full">
          {/* Name and Buttons */}
          <div className="flex flex-row justify-between items-center">
            <div className="text-2xl w-full">
              {firstName} {lastName}
            </div>
            {/* Horizontal Button Group */}
            <div className="flex flex-row items-center justify-end space-x-3 w-full">
              <IconButton
                onClick={handleOpenEmergencyModal}
                className="flex items-center w-10"
                tooltip="Emergency Contact"
                hidden={true}
              >
                <FontAwesomeIcon icon={faPhone} className="text-sm" />
              </IconButton>
              <IconButton onClick={handleOpenDeviceModal} className="flex items-center w-10" tooltip="Device Info">
                <FontAwesomeIcon icon={faMobileAlt} className="text-sm" />
              </IconButton>
              {/* Insight Report Button */}
              {showInsightReportButton && (
                <IconButton to={insightReportPageRoute} className="flex w-42">
                  <FontAwesomeIcon icon={faChartSimple} className="mr-2 text-sm" />
                  <span>Insight Report</span>
                </IconButton>
              )}
              {/* Client Overview Button */}
              {showClientOverviewButton && (
                <IconButton to={clientOverviewPageRoute} className="flex w-42">
                  <FontAwesomeIcon icon={faUser} className="mr-2 text-sm" />
                  <span>Client Overview</span>
                </IconButton>
              )}
            </div>
          </div>

          {/* Basic Profile Info */}
          <div className="w-full h-12">
            <div className="w-full flex flex-row justify-start">
              <ProfileInfoItem value={gender + " " + age} />
              <span className="text-neutral-100 text-sm px-2 pt-1">/</span>
              <ProfileInfoItem value={dob} tooltip="DOB" />
              <span className="text-neutral-100 text-sm px-2 pt-1">/</span>
              <ProfileInfoItem value={email} copyable={true} tooltip="Email" />
              <span className="text-neutral-100 text-sm px-2 pt-1">/</span>
              <ProfileInfoItem value={phone} copyable={true} tooltip="Phone" />
            </div>
          </div>

          {/* Modals */}

          <div>
            {/* Emergency Contact Modal */}
            <Modal
              isOpen={isEmergencyModalOpen}
              size="lg"
              title="Emergency Contact"
              onClose={handleCloseEmergencyModal}
            >
              <div className="p-4">
                <p>{emergencyContact}</p>
              </div>
            </Modal>

            {/* Device Info Modal */}
            <Modal isOpen={isDeviceModalOpen} size="lg" title="Device Info" onClose={handleCloseDeviceModal}>
              <DeviceProvider>
                <div className="p-4">
                  {hardwareVersions.map((version, index) => (
                    <div key={index}>{version}</div>
                  ))}
                </div>
              </DeviceProvider>
            </Modal>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ProfileSummary
