import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons"

import SmallControlButton from "../../../../components/Buttons/SmallControlButton.tsx"
import useActivityInsightStore from "../activity/store/ActivityInsightStore.ts"
import useLocationInsightStore from "../location/store/LocationInsightStore.ts"
import useActiveCaloriesSummaryStore from "../summary/store/ActiveCaloriesSummaryStore.ts"
import useDaylightTimeSummaryStore from "../summary/store/DaylightSummaryStore.ts"
import useExerciseSummaryStore from "../summary/store/ExerciseSummaryStore.ts"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore.ts"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore.ts"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore.ts"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore.ts"
import useStepSummaryStore from "../summary/store/StepSummaryStore.ts"

const StarredChartsRangeSelector = () => {
  // Use the ActivityInsightStore's currentOffsetDays as the base display value.
  const currentOffsetDays = useActivityInsightStore((state) => state.currentOffsetDays)
  const setCurrentActivityOffsetDays = useActivityInsightStore((state) => state.setCurrentOffsetDays)

  // Cardio controls
  const setCurrentHeartRateOffsetDays = useHeartRateSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentHRVOffsetDays = useHRVSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentActiveCaloriesOffsetDays = useActiveCaloriesSummaryStore((state) => state.setCurrentOffsetDays)

  // Location controls
  const setCurrentLocationOffsetDays = useLocationInsightStore((state) => state.setCurrentOffsetDays)
  const setCurrentHomeTimeOffsetDays = useHomeTimeSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentDaylightOffsetDays = useDaylightTimeSummaryStore((state) => state.setCurrentOffsetDays)

  // Additional controls: Sleep, Steps, Exercise
  const setCurrentSleepOffsetDays = useSleepSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentStepsOffsetDays = useStepSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentExerciseOffsetDays = useExerciseSummaryStore((state) => state.setCurrentOffsetDays)

  const dayRange = 30

  const getRangeStartText = () => {
    return `${dayRange + currentOffsetDays - 1}d ago`
  }

  const getRangeEndText = () => {
    return currentOffsetDays === 0 ? "Today" : `${currentOffsetDays}d ago`
  }

  const goToPrev = () => {
    const newOffset = currentOffsetDays + dayRange
    // Update the offset for all related stores
    setCurrentActivityOffsetDays(newOffset)
    setCurrentHeartRateOffsetDays(newOffset)
    setCurrentHRVOffsetDays(newOffset)
    setCurrentActiveCaloriesOffsetDays(newOffset)
    setCurrentLocationOffsetDays(newOffset)
    setCurrentHomeTimeOffsetDays(newOffset)
    setCurrentDaylightOffsetDays(newOffset)
    setCurrentSleepOffsetDays(newOffset)
    setCurrentStepsOffsetDays(newOffset)
    setCurrentExerciseOffsetDays(newOffset)
  }

  const goToNext = () => {
    if (currentOffsetDays - dayRange < 0) {
      return
    }
    const newOffset = currentOffsetDays - dayRange
    // Update the offset for all related stores
    setCurrentActivityOffsetDays(newOffset)
    setCurrentHeartRateOffsetDays(newOffset)
    setCurrentHRVOffsetDays(newOffset)
    setCurrentActiveCaloriesOffsetDays(newOffset)
    setCurrentLocationOffsetDays(newOffset)
    setCurrentHomeTimeOffsetDays(newOffset)
    setCurrentDaylightOffsetDays(newOffset)
    setCurrentSleepOffsetDays(newOffset)
    setCurrentStepsOffsetDays(newOffset)
    setCurrentExerciseOffsetDays(newOffset)
  }

  return (
    <div className="flex items-center space-x-1">
      <div className="text-sm flex flex-row items-center font-medium border rounded-xl bg-white px-3 py-1 select-none">
        <SmallControlButton onClick={goToPrev} icon={faArrowLeftLong} direction="left" />
        <div className="min-w-36 items-center text-center">
          {getRangeStartText()}
          <span className="text-xs"> - </span>
          {getRangeEndText()}
        </div>
        <SmallControlButton onClick={goToNext} icon={faArrowRightLong} direction="right" />
      </div>
    </div>
  )
}

export default StarredChartsRangeSelector
