import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs"

const InsightReportTabs = () => {
  const tabClasses =
    "bg-neutral-50 rounded-full text-neutral-400\
    hover:bg-white\
    data-[state=active]:bg-white data-[state=active]:shadow data-[state=active]:text-black"
  const tabWrapperClasses =
    "bg-neutral-50 rounded-full w-full flex justify-between max-w-[700px] items-center my-8 min-w-[600px] select-none"

  return (
    <TabsList className={tabWrapperClasses}>
      <TabsTrigger className={tabClasses} value={"summary"}>
        Summary
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"activity"}>
        Activity
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"location"}>
        Location
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"surveys"}>
        Surveys
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"habits"}>
        Habits
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"mood"}>
        Mood
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"transcript"}>
        Transcript(Alpha)
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"starred"}>
        <FontAwesomeIcon icon={faStar} />
      </TabsTrigger>
    </TabsList>
  )
}

export default InsightReportTabs
