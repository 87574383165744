import { useParams } from "react-router-dom"

import IconButton from "@/components/Buttons/IconButton"

import useStarredChartsStore from "./store/StarredChartsStore"

const StarredChartsToggleButtons: React.FC = () => {
  const { toggleStarredChart, currentStarredCharts } = useStarredChartsStore()
  const { clientId } = useParams()

  const charts = [
    { id: "sleep", label: "Sleep" },
    { id: "step", label: "Steps" },
    { id: "exercise", label: "Exercise" },
    { id: "activity", label: "Sleep & Steps" },
    { id: "heart-rate", label: "Heart Rate" },
    { id: "hrv", label: "HRV" },
    { id: "active-calories", label: "Active Calories" },
    { id: "home", label: "Home Time" },
    { id: "daylight-time", label: "Daylight" },
    { id: "location", label: "Location" },
  ]

  return (
    <div className="flex flex-wrap gap-2 mb-4">
      {charts.map((chart) => {
        const isStarred = currentStarredCharts.has(chart.id)
        if (!clientId) {
          return
        }
        return (
          <IconButton
            key={chart.id}
            onClick={() => toggleStarredChart(chart.id, clientId)}
            color={isStarred ? "black" : "white"}
            textColor={isStarred ? "white" : "neutral-500"}
          >
            {chart.label}
          </IconButton>
        )
      })}
    </div>
  )
}

export default StarredChartsToggleButtons
