import { parseISO } from "date-fns"
import { format } from "date-fns-tz"

interface AssignmentAvailabilityDatesProps {
  available_to?: string
  available_from?: string
}

function formatAsUTC(isoString: string) {
  const date = parseISO(isoString)
  return format(date, "MMM d, yyyy", { timeZone: "UTC" })
}

const AssignmentAvailabilityDates = ({ available_to, available_from }: AssignmentAvailabilityDatesProps) => {
  let frmt_available_from
  const dateFontStyles = "text-xs text-neutral-300"

  if (available_from) {
    frmt_available_from = formatAsUTC(available_from)
  } else {
    frmt_available_from = undefined
  }

  if (available_to) {
    const frmt_available_to = formatAsUTC(available_to)
    return (
      <div className={dateFontStyles}>
        from {frmt_available_from} to {frmt_available_to}
      </div>
    )
  }

  return <div className={dateFontStyles}>available from {frmt_available_from}</div>
}

export default AssignmentAvailabilityDates
