import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useTranscriptMoodStore from "../store/TranscriptMoodStore"

const TranscriptMoodProvider = ({ children }: { children: ReactNode }) => {
  const setTranscriptMoods = useTranscriptMoodStore((state) => state.setTranscriptMoods)
  const { recordingId, clientId } = useParams()

  const {
    data: transcriptMoodData,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["transcripts-mood", recordingId, clientId],
    retry: false,
    enabled: !!recordingId,
    queryFn: async () => {
      const endpoint = `/llm/structured/generate/mood/${clientId}/${recordingId}`
      const response = await api.get<TranscriptMoodResponse>(endpoint)
      if (response.status === 200) return response.data.data
      throw new Error("Fetching transcript mood failed")
    },
  })

  useEffect(() => {
    if (isSuccess && transcriptMoodData) {
      setTranscriptMoods(transcriptMoodData)
    }
  }, [transcriptMoodData, isSuccess, setTranscriptMoods])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError || !transcriptMoodData)
    return <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load transcript mood</div>

  return <>{children}</>
}

export default TranscriptMoodProvider
