import { useEffect, useRef } from "react"

import SectionContainer from "@/features/Insight/components/atom/SectionContainer"
import RelationshipsGraphD3 from "@/features/Transcript/graph/D3Files/RelationshipsGraphD3.js"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import useRelationshipGraphStore from "../store/RelationshipGraphStore"

const RelationshipsGraph: React.FC = () => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const currentRelationshipGraphData = useRelationshipGraphStore((state) => state.currentRelationshipGraphData)

  useEffect(() => {
    if (isValidSize && currentRelationshipGraphData && chartArea.current) {
      new RelationshipsGraphD3(
        chartArea.current,
        containerSize.width,
        containerSize.height,
        currentRelationshipGraphData,
      )
    }
  }, [containerSize, currentRelationshipGraphData, isValidSize])

  return (
    <SectionContainer
      title="Relationships Graph (experimental feature)"
      description="Graph representation of relationship dynamics"
    >
      <div className="flex w-full rounded-lg bg-white border justify-center overflow-hidden">
        <div className="w-full h-128">
          <div className="w-full h-full overflow-hidden" ref={containerRef}>
            <div className="chart-area flex-1 w-full h-full overflow-hidden" ref={chartArea}></div>
          </div>
        </div>
      </div>
    </SectionContainer>
  )
}

export default RelationshipsGraph
