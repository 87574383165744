import Card from "@/components/Cards/Card.tsx"

import { TimeInDaylightInfo } from "@features/Insight/text/InfoTexts"

import LocationRangeSelector from "../activity/LocationRangeSelector"
import LocationStackChart from "../activity/LocationStackChart"
import SectionContainer from "../atom/SectionContainer"
import ActivityGraph from "../graph/ActivityGraph"
import useDaylightSummaryStore from "../summary/store/DaylightSummaryStore"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore"
import DailyDaylightProvider from "./provider/DailyDaylightProvider"
import DailyHomeTimeProvider from "./provider/DailyHomeTimeProvider"
import LocationInsightProvider from "./provider/LocationInsightProvider"

const LocationSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  const currentHomeTimeResponse = useHomeTimeSummaryStore((state) => state.currentHomeTimeResponse)
  const homeTimeChartData = currentHomeTimeResponse?.chart_data || []
  const currentDaylightResponse = useDaylightSummaryStore((state) => state.currentDaylightResponse)
  const daylightChartData = currentDaylightResponse?.chart_data || []

  return (
    <SectionContainer
      title="Daily Location Insights"
      description="Time is adjusted to the client's timezone"
      headerControls={<LocationRangeSelector />}
    >
      <div className="flex gap-3 flex-col mt-3">
        <Card additionalClasses={cardStyle}>
          <div className="flex gap-3 flex-col">
            <DailyDaylightProvider>
              <ActivityGraph
                graphData={daylightChartData}
                name="Time in Daylight"
                color="#40BEE8"
                info={TimeInDaylightInfo}
              />
            </DailyDaylightProvider>
            <DailyHomeTimeProvider>
              <ActivityGraph graphData={homeTimeChartData} name="Home Time" color="#FFA500" />
            </DailyHomeTimeProvider>
            <LocationInsightProvider>
              <LocationStackChart />
            </LocationInsightProvider>
          </div>
        </Card>
      </div>
    </SectionContainer>
  )
}

export default LocationSection
