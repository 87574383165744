import { ReactNode } from "react"

import NewNavigation from "@/core/Layout/Navigation/NewNavigation.tsx"

import DocumentTitle from "./DocumentTitle.tsx"
import PageContainer from "./PageContainer.tsx"

interface LayoutForChatProps {
  children: ReactNode
  title?: string
}

const LayoutForChat = ({ children, title }: LayoutForChatProps) => {
  return (
    <div className="flex flex-col justify-start items-center h-screen">
      <DocumentTitle title={title} />
      <div className="flex flex-col flex-1 justify-start items-center bg-white w-full">
        <div className="px-8 pt-4 flex flex-col items-center w-full max-w-[1200px]">
          <NewNavigation />
        </div>
        <div className="px-8 pb-4 flex flex-col items-center w-full max-w-[1200px] flex-1">
          <PageContainer>{children}</PageContainer>
        </div>
      </div>
    </div>
  )
}

export default LayoutForChat
