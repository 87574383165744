import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"

import useChatStore from "./stores/ChatStore"

const ChatInput: React.FC = () => {
  const [input, setInput] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const addMessage = useChatStore((state) => state.addMessage)
  const removeMessage = useChatStore((state) => state.removeMessage)
  const editingMessage = useChatStore((state) => state.editingMessage)
  const clearEditingMessage = useChatStore((state) => state.clearEditingMessage)
  const queryClient = useQueryClient()
  const sendMessageToVertexAI = useChatStore((state) => state.sendMessageToVertexAI)

  // Pre-fill input field if editing a message
  useEffect(() => {
    if (editingMessage) {
      setInput(editingMessage)
      clearEditingMessage()
    }
  }, [editingMessage, clearEditingMessage])

  const handleSend = async () => {
    if (!input.trim()) return

    setIsLoading(true)

    // Create and add a new user message
    const userMessage = {
      id: crypto.randomUUID(),
      role: "user" as const,
      text: input.trim(),
      timestamp: Date.now().toString(),
    }
    addMessage(userMessage)
    setInput("")

    // Add a temporary loading message if reply takes too long
    let loadingMessageId: string | null = null
    const loadingTimer = setTimeout(() => {
      loadingMessageId = crypto.randomUUID()
      addMessage({
        id: loadingMessageId,
        role: "ai-assistant" as const,
        text: "",
        timestamp: Date.now().toString(),
      })
    }, 300)

    try {
      const llmReply = await sendMessageToVertexAI(userMessage.text, queryClient)
      clearTimeout(loadingTimer)
      if (loadingMessageId) {
        removeMessage(loadingMessageId)
      }
      const llmMessage = {
        id: crypto.randomUUID(),
        role: "ai-assistant" as const,
        text: llmReply,
        timestamp: Date.now().toString(),
      }
      addMessage(llmMessage)
    } catch (error) {
      clearTimeout(loadingTimer)
      if (loadingMessageId) {
        removeMessage(loadingMessageId)
      }
      console.error("Error fetching ai-assistant reply:", error)
      const errorMessage = {
        id: crypto.randomUUID(),
        role: "ai-assistant" as const,
        text: "Sorry, an error occurred while fetching the reply.",
        timestamp: Date.now().toString(),
      }
      addMessage(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col w-full h-full items-end">
      <textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your message..."
        rows={10}
        className="w-full p-4 border rounded-xl focus:outline-none resize-none focus:border-neutral-100 focus:shadow-xl transition duration-500"
      />
      <button
        onClick={() => void handleSend()}
        disabled={!input.trim() || isLoading}
        className="mt-4 p-2 bg-neutral-950 text-white rounded-full hover:bg-neutral-800 transition active:bg-neutral-950 max-w-60 min-w-40"
      >
        {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Send"}
      </button>
    </div>
  )
}

export default ChatInput
