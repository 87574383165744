import { ROLES } from "@/constants/roles.const"

import useAuthStore from "../Authentication/stores/AuthStore"

const SecurityBanner = () => {
  const user = useAuthStore((state) => state.user)

  if (user.roles.includes(ROLES.DEBUGGER))
    return (
      <div className="bg-red-500 text-white p-4 w-full text-3xl text-center">
        WARNING: you are running as a debugger user.
      </div>
    )
}

export default SecurityBanner
