import { create } from "zustand"

interface ActivityInsightStore {
  currentActivityResponse: ActivityStackResponse | null
  setCurrentActivityResponse: (response: ActivityStackResponse) => void
  currentGraphFormat: string
  setCurrentGraphFormat: (format: string) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
  resetDateRange: () => void
}

const useActivityInsightStore = create<ActivityInsightStore>((set) => ({
  currentActivityResponse: null,
  setCurrentActivityResponse: (currentActivityResponse) => set({ currentActivityResponse }),
  currentGraphFormat: "Timeline",
  setCurrentGraphFormat: (currentGraphFormat) => set({ currentGraphFormat }),
  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
  resetDateRange: () => set({ currentOffsetDays: 0, currentLimitDays: 30 }),
}))

export default useActivityInsightStore
