import { useState } from "react"

import AssignmentInsightPage from "@/features/Clients/ClientAssignmentPage.tsx"
import ClientProfileHeader from "@/features/Insight/components/header/ClientProfileHeader.tsx"
import { Tabs, TabsContent } from "@/shadcn-components/ui/tabs"

import SummaryHeaderProvider from "@features/Insight/components/summary/provider/SummaryHeaderProvider.tsx"

import CareCirclePage from "./CareCirclePage.tsx"
import ClientOverviewTabs from "./ClientOverviewTabs.tsx"
import ClientPermissionsPage from "./ClientPermissionsPage.tsx"
import ClientProfileDetailsPage from "./ClientProfileDetailsPage.tsx"

const ClientOverview = () => {
  const [, setActiveTab] = useState("client-overview")

  return (
    <div className="flex flex-col items-center pb-8 w-full">
      <SummaryHeaderProvider>
        <ClientProfileHeader headerType="client-overview" />
      </SummaryHeaderProvider>
      <Tabs defaultValue="profile-details" onValueChange={(value) => setActiveTab(value)} className="w-full">
        <div className="flex justify-center items-center">
          <ClientOverviewTabs />
        </div>

        <TabsContent value="profile-details">
          <ClientProfileDetailsPage />
        </TabsContent>
        <TabsContent value="care-circle">
          <CareCirclePage />
        </TabsContent>
        <TabsContent value="assignments">
          <AssignmentInsightPage />
        </TabsContent>
        <TabsContent value="permissions">
          <ClientPermissionsPage />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default ClientOverview
