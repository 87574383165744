import { create } from "zustand"

interface SurveysInsightStore {
  currentSurveysInsight: SurveysInsightResponse | null
  setCurrentSurveysInsight: (currentSurveysInsight: SurveysInsightResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
  resetDateRange: () => void
}

const useSurveysInsightStore = create<SurveysInsightStore>((set) => ({
  currentSurveysInsight: null,
  currentOffsetDays: 0,
  currentLimitDays: 30,
  setCurrentSurveysInsight: (currentSurveysInsight) => set({ currentSurveysInsight }),
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
  resetDateRange: () => set({ currentOffsetDays: 0, currentLimitDays: 30 }),
}))

export default useSurveysInsightStore
