import useActivityInsightStore from "../activity/store/ActivityInsightStore"
import useLocationInsightStore from "../location/store/LocationInsightStore"
import useActiveCaloriesSummaryStore from "../summary/store/ActiveCaloriesSummaryStore"
import useDaylightSummaryStore from "../summary/store/DaylightSummaryStore"
import useExerciseSummaryStore from "../summary/store/ExerciseSummaryStore"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import StarredChartsSection from "./StarredChartsSection"
import StarredChartsToggleButtons from "./StarredChartsToggleButtons"
import StarredChartsProvider from "./provider/StarredChartsProvider"

const StarredChartsPage: React.FC = () => {
  // Extract the reset functions from each store
  const resetActivityDateRange = useActivityInsightStore((state) => state.resetDateRange)
  const resetLocationDateRange = useLocationInsightStore((state) => state.resetDateRange)
  const resetActiveCaloriesSummaryDateRange = useActiveCaloriesSummaryStore((state) => state.resetDateRange)
  const resetDaylightSummaryDateRange = useDaylightSummaryStore((state) => state.resetDateRange)
  const resetExerciseSummaryDateRange = useExerciseSummaryStore((state) => state.resetDateRange)
  const resetHRVSummaryDateRange = useHRVSummaryStore((state) => state.resetDateRange)
  const resetHeartRateSummaryDateRange = useHeartRateSummaryStore((state) => state.resetDateRange)
  const resetHomeTimeSummaryDateRange = useHomeTimeSummaryStore((state) => state.resetDateRange)
  const resetSleepSummaryDateRange = useSleepSummaryStore((state) => state.resetDateRange)
  const resetStepSummaryDateRange = useStepSummaryStore((state) => state.resetDateRange)

  // Call the reset functions when the component mounts
  resetActivityDateRange()
  resetLocationDateRange()
  resetActiveCaloriesSummaryDateRange()
  resetDaylightSummaryDateRange()
  resetExerciseSummaryDateRange()
  resetHRVSummaryDateRange()
  resetHeartRateSummaryDateRange()
  resetHomeTimeSummaryDateRange()
  resetSleepSummaryDateRange()
  resetStepSummaryDateRange()

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Customized View</h3>
      </div>
      <StarredChartsProvider>
        <div>
          <StarredChartsToggleButtons />
        </div>
        <StarredChartsSection />
      </StarredChartsProvider>
    </div>
  )
}

export default StarredChartsPage
