import { ExerciseTimeInfo } from "@features/Insight/text/InfoTexts"

import ActivityGraph from "../graph/ActivityGraph"
import useExerciseSummaryStore from "../summary/store/ExerciseSummaryStore"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import DailyExerciseActivityProvider from "./provider/DailyExerciseActivityProvider"
import DailySleepActivityProvider from "./provider/DailySleepActivityProvider"
import DailyStepsActivityProvider from "./provider/DailyStepsActivityProvider"

const DailySleepAndStepsCard: React.FC = () => {
  const { currentStepsResponse } = useStepSummaryStore()
  const stepsChartData = currentStepsResponse?.chart_data || []

  const { currentSleepResponse } = useSleepSummaryStore()
  const sleepChartData = currentSleepResponse?.chart_data || []

  const { currentExerciseResponse } = useExerciseSummaryStore()
  const exerciseChartData = currentExerciseResponse?.chart_data || []

  return (
    <div className="flex gap-3 flex-col">
      <DailySleepActivityProvider>
        <ActivityGraph graphData={sleepChartData} name="Hours Slept" color="#5E89FF" />
      </DailySleepActivityProvider>
      <DailyStepsActivityProvider>
        <ActivityGraph graphData={stepsChartData} name="Daily Steps" color="#01B85E" />
      </DailyStepsActivityProvider>
      <DailyExerciseActivityProvider>
        <ActivityGraph
          graphData={exerciseChartData}
          name="Daily Exercise Time"
          color="#F98707"
          info={ExerciseTimeInfo}
        />
      </DailyExerciseActivityProvider>
    </div>
  )
}

export default DailySleepAndStepsCard
