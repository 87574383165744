import TranscriptItemCard from "./TranscriptItemCard"
import useTranscriptStore from "./store/TranscriptListStore"

const TranscriptList: React.FC = () => {
  const transcriptList = useTranscriptStore((state) => state.currentTranscriptList)

  return (
    <div>
      {transcriptList.map((transcript: Transcript) => (
        <TranscriptItemCard key={transcript.recording_id} transcript={transcript} />
      ))}
    </div>
  )
}

export default TranscriptList
