export const permissionsMap = {
  "sharing:assignment": {
    title: "Assignments",
    description: "Allow this person to access your assignments, including the answers you provide to surveys.",
    description_for_provider:
      "When enabled by the client, this setting grants you access to their assignments, including their survey responses.",
  },
  "sharing:private_profile": {
    title: "Profile Information",
    description: "Allow this person to access your profile information, including your name, email, and phone number.",
    description_for_provider:
      "When enabled by the client, this setting grants you access to their profile information, including their name, email, and phone number.",
  },
  "sharing:consent": {
    title: "Consent",
    description: "Allow this person to access your consent forms.",
    description_for_provider: "When enabled by the client, this setting grants you access to their consent forms.",
  },
  "sharing:meeting_member": {
    title: "Meeting Members",
    description: "Allow this person to access the attendees of your meetings.",
    description_for_provider:
      "When enabled by the client, this setting grants you access to see the attendees of their meetings.",
  },
  "sharing:private_care_level": {
    title: "Care Level",
    description: "Allow this person to access your care level.",
    description_for_provider: "When enabled by the client, this setting grants you access to their care level.",
  },
  "sharing:private_medication": {
    title: "Medications",
    description: "Allow this person to access your medication.",
    description_for_provider: "When enabled by the client, this setting grants you access to their medication.",
  },
  "sharing:private_diagnosis": {
    title: "Diagnosis",
    description: "Allow this person to access your diagnosis.",
    description_for_provider: "When enabled by the client, this setting grants you access to their diagnosis.",
  },
  "sharing:question_response": {
    title: "Question Responses",
    description: "Allow this person to access your question responses.",
    description_for_provider: "When enabled by the client, this setting grants you access to their question responses.",
  },
  "sharing:user": {
    title: "Account Information",
    description: "Allow this person to access your user information.",
    description_for_provider: "When enabled by the client, this setting grants you access to their user information.",
  },
  "sharing:private_program": {
    title: "Programs",
    description: "Allow this person to access your program information.",
    description_for_provider:
      "When enabled by the client, this setting grants you access to their program information.",
  },
  "sharing:private_treatment": {
    title: "Treatments",
    description: "Allow this person to access your treatment information.",
    description_for_provider:
      "When enabled by the client, this setting grants you access to their treatment information.",
  },
  "sharing:sms_conversation": {
    title: "SMS Conversations",
    description: "Allow this person to access to checkins via SMS.",
    description_for_provider: "When enabled by the client, this setting grants you access to their checkins via SMS.",
  },
  "sharing:task_event": {
    title: "Task Events",
    description: "Allow this person to access your task events.",
    description_for_provider: "When enabled by the client, this setting grants you access to their task events.",
  },
  "sharing:healthkit_data": {
    title: "HealthKit Data",
    description:
      "Allow this person to access your HealthKit data from your iOS device, including data from wearables such as Apple Watch.",
    description_for_provider:
      "When enabled by the client, this setting grants you access to their HealthKit data from their iOS device, including data from wearables such as Apple Watch.",
  },
  "sharing:mood": {
    title: "Mood",
    description: "Allow this person to access your mood data.",
    description_for_provider: "When enabled by the client, this setting grants you access to their mood data.",
  },
  "sharing:feed_item": {
    title: "Feed Items",
    description: "Allow this person to access your feed items.",
    description_for_provider: "When enabled by the client, this setting grants you access to their feed items.",
  },
}
