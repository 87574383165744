import Card from "@/components/Cards/Card.tsx"

import { ActiveCaloriesInfo, ExerciseTimeInfo, HRVInfo, TimeInDaylightInfo } from "@features/Insight/text/InfoTexts"

import LocationStackChart from "../activity/LocationStackChart"
import SleepAndStepsCard from "../activity/SleepAndStepsCard"
import ActivityStackGraphProvider from "../activity/provider/ActivityStackGraphProvider"
import DailyActiveCaloriesProvider from "../activity/provider/DailyActiveCaloriesProvider"
import DailyExerciseActivityProvider from "../activity/provider/DailyExerciseActivityProvider"
import DailyHRVChartProvider from "../activity/provider/DailyHRVChartProvider"
import DailyHeartRateChartProvider from "../activity/provider/DailyHeartRateChartProvider"
import DailySleepActivityProvider from "../activity/provider/DailySleepActivityProvider"
import DailyStepsActivityProvider from "../activity/provider/DailyStepsActivityProvider"
import SectionContainer from "../atom/SectionContainer"
import ActivityGraph from "../graph/ActivityGraph"
import DailyDaylightProvider from "../location/provider/DailyDaylightProvider"
import DailyHomeTimeProvider from "../location/provider/DailyHomeTimeProvider"
import LocationInsightProvider from "../location/provider/LocationInsightProvider"
import useActiveCaloriesSummaryStore from "../summary/store/ActiveCaloriesSummaryStore"
import useDaylightSummaryStore from "../summary/store/DaylightSummaryStore"
import useExerciseSummaryStore from "../summary/store/ExerciseSummaryStore"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import StarredChartsRangeSelector from "./StarredChartsRangeSelector"
import useStarredChartsStore from "./store/StarredChartsStore"

const StarredChartsSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  const { currentStepsResponse } = useStepSummaryStore()
  const stepsChartData = currentStepsResponse?.chart_data || []

  const { currentSleepResponse } = useSleepSummaryStore()
  const sleepChartData = currentSleepResponse?.chart_data || []

  const { currentExerciseResponse } = useExerciseSummaryStore()
  const exerciseChartData = currentExerciseResponse?.chart_data || []

  const { currentStarredCharts } = useStarredChartsStore()

  const { currentHRVResponse } = useHRVSummaryStore()
  const hrvChartData = currentHRVResponse?.chart_data || []

  const { currentHeartRateResponse } = useHeartRateSummaryStore()
  const heartRateChartData = currentHeartRateResponse?.chart_data || []

  const { currentActiveCaloriesResponse } = useActiveCaloriesSummaryStore()
  const activeCaloriesChartData = currentActiveCaloriesResponse?.chart_data || []

  const { currentHomeTimeResponse } = useHomeTimeSummaryStore()
  const homeTimeChartData = currentHomeTimeResponse?.chart_data || []

  const { currentDaylightResponse } = useDaylightSummaryStore()
  const daylightChartData = currentDaylightResponse?.chart_data || []

  if (currentStarredCharts.size === 0) {
    return (
      <SectionContainer
        title="Customized View"
        description="Select the charts to display"
        headerControls={<StarredChartsRangeSelector />}
      >
        <div className="flex gap-3 flex-col mt-3">
          <Card additionalClasses={`${cardStyle} space-y-6`}>
            <div className="text-center text-neutral-200">No charts selected</div>
          </Card>
        </div>
      </SectionContainer>
    )
  }

  return (
    <SectionContainer
      title="Customized View"
      description="Select the charts to display"
      headerControls={<StarredChartsRangeSelector />}
    >
      <div className="flex gap-3 flex-col mt-3">
        <Card additionalClasses={`${cardStyle} space-y-6`}>
          {currentStarredCharts.has("sleep") && (
            <DailySleepActivityProvider>
              <ActivityGraph graphData={sleepChartData} name="Hours Slept" color="#5E89FF" />
            </DailySleepActivityProvider>
          )}
          {currentStarredCharts.has("step") && (
            <DailyStepsActivityProvider>
              <ActivityGraph graphData={stepsChartData} name="Daily Steps" color="#01B85E" />
            </DailyStepsActivityProvider>
          )}
          {currentStarredCharts.has("exercise") && (
            <DailyExerciseActivityProvider>
              <ActivityGraph
                graphData={exerciseChartData}
                name="Daily Exercise Time"
                color="#F98707"
                info={ExerciseTimeInfo}
              />
            </DailyExerciseActivityProvider>
          )}
          {currentStarredCharts.has("activity") && (
            <ActivityStackGraphProvider>
              <SleepAndStepsCard />
            </ActivityStackGraphProvider>
          )}
          {currentStarredCharts.has("heart-rate") && (
            <DailyHeartRateChartProvider>
              <ActivityGraph graphData={heartRateChartData} name="Heart Rate" color="#F85884" useMinMax={true} />
            </DailyHeartRateChartProvider>
          )}
          {currentStarredCharts.has("hrv") && (
            <DailyHRVChartProvider>
              <ActivityGraph graphData={hrvChartData} name="HRV" color="#F85884" info={HRVInfo} />
            </DailyHRVChartProvider>
          )}
          {currentStarredCharts.has("active-calories") && (
            <DailyActiveCaloriesProvider>
              <ActivityGraph
                graphData={activeCaloriesChartData}
                name="Active Calories"
                color="#FF8066"
                info={ActiveCaloriesInfo}
              />
            </DailyActiveCaloriesProvider>
          )}
          {currentStarredCharts.has("home") && (
            <DailyHomeTimeProvider>
              <ActivityGraph graphData={homeTimeChartData} name="Home Time" color="#FFA500" />
            </DailyHomeTimeProvider>
          )}
          {currentStarredCharts.has("daylight-time") && (
            <DailyDaylightProvider>
              <ActivityGraph
                graphData={daylightChartData}
                name="Time in Daylight"
                color="#40BEE8"
                info={TimeInDaylightInfo}
              />
            </DailyDaylightProvider>
          )}
          {currentStarredCharts.has("location") && (
            <LocationInsightProvider>
              <LocationStackChart />
            </LocationInsightProvider>
          )}
        </Card>
      </div>
    </SectionContainer>
  )
}

export default StarredChartsSection
