import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface IconInfoCardProps {
  icon: IconDefinition
  title: string
  description: React.ReactNode
  containerPadding?: string
}

const IconInfoCard: React.FC<IconInfoCardProps> = ({ icon, title, description, containerPadding = "p-3" }) => {
  return (
    <div className={`rounded-xl border ${containerPadding} flex flex-row`}>
      <FontAwesomeIcon className="text-neutral-300 pt-1" icon={icon} />
      <div className="pl-2">
        <span>{title}</span>
        <div className="text-neutral-300 text-sm mt-1">{description}</div>
      </div>
    </div>
  )
}

export default IconInfoCard
