import ClientRelationshipsProvider from "@features/CareCircleNew/providers/ClientRelationshipsProvider.tsx"
import ClientRelationshipsOverview from "@features/Clients/Components/Molecules/ClientRelationshipsOverview.tsx"

import ClientOverviewContainer from "./ClientOverviewContainer"

const CareCirclePage = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Care Circle</h3>
        <ClientOverviewContainer title="Accounts related to this client">
          <ClientRelationshipsProvider>
            <ClientRelationshipsOverview />
          </ClientRelationshipsProvider>
        </ClientOverviewContainer>
      </div>
    </div>
  )
}

export default CareCirclePage
