import BackButton from "../Insight/components/header/BackButton"

interface TranscriptHeaderProps {
  title: string
  createdAt: number | string
}

const TranscriptHeader: React.FC<TranscriptHeaderProps> = ({ title, createdAt }) => {
  return (
    <div className="flex flex-row justify-start items-center">
      <div className="flex flex-col h-12 justify-center items-center mr-3 ml-0.5">
        <BackButton />
      </div>
      <div className="flex-col">
        <div className="text-3xl font-black">{title}</div>
        <div>{new Date(createdAt).toLocaleString()}</div>
      </div>
    </div>
  )
}

export default TranscriptHeader
