import { faArrowLeftLong, faArrowRightLong, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"

import SmallControlButton from "../../../../components/Buttons/SmallControlButton.tsx"
import useExerciseSummaryStore from "../summary/store/ExerciseSummaryStore.ts"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore.ts"
import useStepSummaryStore from "../summary/store/StepSummaryStore.ts"
import useActivityInsightStore from "./store/ActivityInsightStore.ts"

interface ActivityRangeSelectorProps {
  allowRangeControl?: boolean
}

const ActivityRangeSelector: React.FC<ActivityRangeSelectorProps> = ({ allowRangeControl = true }) => {
  const currentOffsetDays = useActivityInsightStore((state) => state.currentOffsetDays)
  const setCurrentSleepOffsetDays = useSleepSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentStepsOffsetDays = useStepSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentExerciseOffsetDays = useExerciseSummaryStore((state) => state.setCurrentOffsetDays)
  const setCurrentActivityOffsetDays = useActivityInsightStore((state) => state.setCurrentOffsetDays)

  const currentLimitDays = useActivityInsightStore((state) => state.currentLimitDays)
  const setCurrentActivityLimitDays = useActivityInsightStore((state) => state.setCurrentLimitDays)
  const setCurrentSleepLimitDays = useSleepSummaryStore((state) => state.setCurrentLimitDays)
  const setCurrentStepsLimitDays = useStepSummaryStore((state) => state.setCurrentLimitDays)
  const setCurrentExerciseLimitDays = useExerciseSummaryStore((state) => state.setCurrentLimitDays)

  const ranges = [7, 30, 90]
  const currentRangeIndex = ranges.indexOf(currentLimitDays)

  const getRangeStartText = () => {
    return `${currentLimitDays + currentOffsetDays - 1}d ago`
  }

  const getRangeEndText = () => {
    if (currentOffsetDays === 0) {
      return "Today"
    }
    return `${currentOffsetDays}d ago`
  }

  const goToPrev = () => {
    setCurrentSleepOffsetDays(currentOffsetDays + currentLimitDays)
    setCurrentStepsOffsetDays(currentOffsetDays + currentLimitDays)
    setCurrentExerciseOffsetDays(currentOffsetDays + currentLimitDays)
    setCurrentActivityOffsetDays(currentOffsetDays + currentLimitDays)
  }

  const goToNext = () => {
    if (currentOffsetDays - currentLimitDays < 0) {
      return
    }
    setCurrentSleepOffsetDays(currentOffsetDays - currentLimitDays)
    setCurrentStepsOffsetDays(currentOffsetDays - currentLimitDays)
    setCurrentExerciseOffsetDays(currentOffsetDays - currentLimitDays)
    setCurrentActivityOffsetDays(currentOffsetDays - currentLimitDays)
  }

  const increaseRange = () => {
    if (currentRangeIndex < ranges.length - 1) {
      setCurrentActivityLimitDays(ranges[currentRangeIndex + 1])
      setCurrentSleepLimitDays(ranges[currentRangeIndex + 1])
      setCurrentStepsLimitDays(ranges[currentRangeIndex + 1])
      setCurrentExerciseLimitDays(ranges[currentRangeIndex + 1])
    }
  }

  const decreaseRange = () => {
    if (currentRangeIndex > 0) {
      setCurrentActivityLimitDays(ranges[currentRangeIndex - 1])
      setCurrentSleepLimitDays(ranges[currentRangeIndex - 1])
      setCurrentStepsLimitDays(ranges[currentRangeIndex - 1])
      setCurrentExerciseLimitDays(ranges[currentRangeIndex - 1])
    }
  }

  return (
    <div className="flex items-center space-x-1">
      {allowRangeControl && (
        <div className="flex flex-row rounded-xl bg-white border px-3 py-1 text-sm font-medium">
          <SmallControlButton onClick={increaseRange} icon={faPlus} direction="left" />
          <div className="min-w-20 items-center text-center">{currentLimitDays} days</div>
          <SmallControlButton onClick={decreaseRange} icon={faMinus} direction="right" />
        </div>
      )}
      <div className="text-sm flex flex-row items-center font-medium border rounded-xl bg-white px-3 py-1 select-none">
        <SmallControlButton onClick={goToPrev} icon={faArrowLeftLong} direction="left" />
        <div className="min-w-36 items-center text-center">
          {getRangeStartText()}
          <span className="text-xs"> - </span>
          {getRangeEndText()}
        </div>
        <SmallControlButton onClick={goToNext} icon={faArrowRightLong} direction="right" />
      </div>
    </div>
  )
}

export default ActivityRangeSelector
