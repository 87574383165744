import useLocationInsightStore from "../location/store/LocationInsightStore"
import useDaylightSummaryStore from "../summary/store/DaylightSummaryStore"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore"
import LocationSection from "./LocationSection"

const LocationPage: React.FC = () => {
  // Extract the reset functions from each store
  const resetLocationDateRange = useLocationInsightStore((state) => state.resetDateRange)
  const resetDaylightSummaryDateRange = useDaylightSummaryStore((state) => state.resetDateRange)
  const resetHomeTimeSummaryDateRange = useHomeTimeSummaryStore((state) => state.resetDateRange)

  // Call the reset functions when the component mounts
  resetLocationDateRange()
  resetDaylightSummaryDateRange()
  resetHomeTimeSummaryDateRange()

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Location</h3>
      </div>
      <LocationSection />
    </div>
  )
}

export default LocationPage
