import { create } from "zustand"

interface RelationshipGraphState {
  currentRelationshipGraphData: RelationshipGraphData | null
  setCurrentRelationshipGraphData: (data: RelationshipGraphData | null) => void
}

const useRelationshipGraphStore = create<RelationshipGraphState>((set) => ({
  currentRelationshipGraphData: null,
  setCurrentRelationshipGraphData: (data) => set({ currentRelationshipGraphData: data }),
}))

export default useRelationshipGraphStore
