import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import IconButton from "@/components/Buttons/IconButton"

import useChatStore from "@features/LLM/stores/ChatStore"

const SessionsList: React.FC = () => {
  const sessions = useChatStore((state) => state.sessions)
  const loadChatHistory = useChatStore((state) => state.loadChatHistory)
  const clearMessages = useChatStore((state) => state.clearMessages)
  const setCurretnChatSessionId = useChatStore((state) => state.setCurretnChatSessionId)
  const currentChatSessionId = useChatStore((state) => state.current_chat_session_id)

  const MAX_LENGTH = 50

  // Create new chat by clearing messages and resetting session id
  const createNewChat = () => {
    setCurretnChatSessionId(null)
    clearMessages()
  }

  return (
    <div className="border-r w-72 min-w-72 max-w-72 h-full flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <h1 className="text-2xl font-bold mb-4">Sessions</h1>

        {/* Buttons */}
        <div className="mr-4 mb-3 flex flex-row space-x-2">
          <IconButton onClick={createNewChat}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            New
          </IconButton>
        </div>
      </div>

      {sessions.length === 0 ? (
        <p className="text-sm text-neutral-500">No sessions available.</p>
      ) : (
        // Wrap the sessions list in a scrollable container.
        <div
          className="overflow-y-auto flex-1
          [&::-webkit-scrollbar]:w-1
          [&::-webkit-scrollbar-track]:bg-neutral-50
          [&::-webkit-scrollbar-thumb]:bg-neutral-200"
        >
          {sessions.map((session) => {
            const isSelected = session.chat_session_id === currentChatSessionId
            return (
              <div
                key={session.chat_session_id}
                title={
                  session.session_name && session.session_name.length > MAX_LENGTH ? session.session_name.trim() : ""
                }
                className={`rounded-xl mb-3 mr-4 p-4 transition duration-300 ease-in-out cursor-pointer ${
                  isSelected ? "bg-neutral-950 border text-white" : "bg-white border hover:bg-neutral-50"
                }`}
                onClick={() => void loadChatHistory(session.chat_session_id)}
              >
                {session.session_name
                  ? session.session_name.length > MAX_LENGTH
                    ? session.session_name.substring(0, MAX_LENGTH) + "..."
                    : session.session_name
                  : "New Session"}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SessionsList
