import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useTranscriptDetailStore from "../store/TranscriptDetailStore"

const TranscriptDetailProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentTranscriptDetail = useTranscriptDetailStore((state) => state.setcurrentTranscriptDetail)
  const { clientId, recordingId } = useParams()
  const { hash } = useLocation()

  const {
    data: transcriptDetailData,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["transcripts-detail", recordingId, clientId],
    enabled: !!recordingId,
    retry: false,
    queryFn: async () => {
      const endpoint = `sessions/transcripts/${clientId}/${recordingId}`
      const response = await api.get<TranscriptDetailResponse>(endpoint)
      if (response.status === 200) return response.data.data
      throw new Error("Fetching transcript detail failed")
    },
  })

  useEffect(() => {
    if (isSuccess && transcriptDetailData) {
      setCurrentTranscriptDetail(transcriptDetailData)
      setTimeout(() => {
        const element = document.getElementById(hash.replace("#", ""))
        if (element) element.scrollIntoView({ behavior: "smooth" })
      }, 100)
    }
  }, [transcriptDetailData, isSuccess, setCurrentTranscriptDetail, hash])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError || !transcriptDetailData)
    return <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load transcript detail</div>

  return <>{children}</>
}

export default TranscriptDetailProvider
