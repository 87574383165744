import { ReactNode } from "react"

import NewFooter from "@/core/Layout/Footer/NewFooter.tsx"
import NewNavigation from "@/core/Layout/Navigation/NewNavigation.tsx"

import DocumentTitle from "./DocumentTitle.tsx"
import PageContainer from "./PageContainer.tsx"
import SecurityBanner from "./SecurityBanner.tsx"

interface LayoutProps {
  children: ReactNode
  title?: string
}

const Layout = ({ children, title }: LayoutProps) => {
  return (
    <div className="flex flex-col justify-start items-center min-h-screen">
      <DocumentTitle title={title} />
      <SecurityBanner />
      <div className="flex flex-col flex-1 justify-start items-center bg-white w-full">
        <div className="px-8 pt-4 flex flex-col items-center w-full max-w-[1200px]">
          <NewNavigation />
        </div>

        <div className="px-8 pb-4 flex flex-col items-center w-full max-w-[1200px]">
          <PageContainer>{children}</PageContainer>
        </div>

        <div className="px-8 py-4 flex flex-col items-center w-full max-w-[1200px]">
          <NewFooter />
        </div>
      </div>
    </div>
  )
}

export default Layout
