import { create } from "zustand"

interface TranscriptDetailStore {
  currentTranscriptDetail: TranscriptDetail | null
  setcurrentTranscriptDetail: (transcriptDetail: TranscriptDetail | null) => void
}

const useTranscriptDetailStore = create<TranscriptDetailStore>((set) => ({
  currentTranscriptDetail: null,
  setcurrentTranscriptDetail: (transcriptDetail) => set({ currentTranscriptDetail: transcriptDetail }),
}))

export default useTranscriptDetailStore
