import { useEffect, useRef } from "react"

import TranscriptMoodD3 from "@/features/Transcript/graph/D3Files/TranscriptMoodD3.js"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import useRelationshipMoodStore from "../store/RelationshipMoodStore"

interface RelationshipMoodGraphProps {
  startFromIndex?: number
  enableAutoPlay?: boolean
}

const RelationshipMoodGraph = ({ startFromIndex = 0, enableAutoPlay = false }: RelationshipMoodGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const currentGraphData = useRelationshipMoodStore((state) => state.relationshipMoods)
  const validData = currentGraphData
  const transitionDuration = 3000
  const showControl = false

  useEffect(() => {
    if (isValidSize && validData) {
      new TranscriptMoodD3(
        chartArea.current,
        containerSize.width,
        containerSize.height,
        currentGraphData,
        transitionDuration,
        enableAutoPlay,
        startFromIndex,
        showControl,
      )
    }
  }, [
    containerSize,
    currentGraphData,
    isValidSize,
    validData,
    transitionDuration,
    enableAutoPlay,
    startFromIndex,
    showControl,
  ])

  return (
    <div className="w-full h-full overflow-hidden" ref={containerRef}>
      <div className="chart-area flex-1 w-full h-full overflow-hidden" ref={chartArea}></div>
    </div>
  )
}

export default RelationshipMoodGraph
