import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useTranscriptCommentsStore from "../store/TranscriptCommentsStore"

const TranscriptCommentsProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentTranscriptComments = useTranscriptCommentsStore((state) => state.setCurrentTranscriptComments)
  const { recordingId } = useParams()

  const {
    data: transcriptCommentsData,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["transcriptComments", recordingId],
    enabled: !!recordingId,
    retry: false,
    queryFn: async () => {
      const endpoint = `sessions/comments/${recordingId}`
      const response = await api.get<TranscriptCommentsResponse>(endpoint)
      if (response.status === 200) return response.data.data
      throw new Error("Fetching transcript comments failed")
    },
  })

  useEffect(() => {
    if (isSuccess && transcriptCommentsData) {
      setCurrentTranscriptComments(transcriptCommentsData)
    }
  }, [transcriptCommentsData, isSuccess, setCurrentTranscriptComments])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError || !transcriptCommentsData)
    return <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load transcript comments</div>

  return <>{children}</>
}

export default TranscriptCommentsProvider
