import { useEffect, useState } from "react"

import ProfileInfoItem from "@/features/Insight/components/atom/ProfileInfoItem.tsx"

import useSummaryHeaderStore from "../summary/store/SummaryHeaderStore"

const ProfileBar: React.FC = () => {
  const { currentSummaryHeaderResponse } = useSummaryHeaderStore()
  const [showProfileBar, setShowProfileBar] = useState(false)

  useEffect(() => {
    if (!currentSummaryHeaderResponse) return

    const handleScroll = () => {
      const scrollDistance = 200 // show profile bar after scrolling 200px
      setShowProfileBar(window.scrollY >= scrollDistance)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [currentSummaryHeaderResponse])

  // Conditional rendering after hooks have been called
  if (!currentSummaryHeaderResponse) {
    return null
  }

  const { profile_data } = currentSummaryHeaderResponse
  const firstName = profile_data?.first_name || ""
  const lastName = profile_data?.last_name || ""
  const age = profile_data?.age?.toString() || "..."
  const dob = profile_data?.birthdate || "..."
  const gender = profile_data?.sex || "..."
  const phone = profile_data?.phone || "..."
  const email = profile_data?.email || "..."

  return (
    <div
      className={`fixed top-0 z-50 transition-opacity duration-200 ease-in-out ${
        showProfileBar ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="flex flex-row w-full justify-between select-none border rounded-b-xl shadow-md bg-white">
        {/* Basic Profile Info */}
        <div className="w-full flex flex-row justify-between items-center px-3 pb-1">
          <ProfileInfoItem value={`${firstName} ${lastName}`} />
          <span className="text-neutral-100 text-sm px-2 pt-1">/</span>
          <ProfileInfoItem value={`${gender} ${age}`} />
          <span className="text-neutral-100 text-sm px-2 pt-1">/</span>
          <ProfileInfoItem value={dob} />
          <span className="text-neutral-100 text-sm px-2 pt-1">/</span>
          <ProfileInfoItem value={email} />
          <span className="text-neutral-100 text-sm px-2 pt-1">/</span>
          <ProfileInfoItem value={phone} />
        </div>
      </div>
    </div>
  )
}

export default ProfileBar
