import { TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs"

const ClientOverviewTabs = () => {
  const tabClasses =
    "bg-neutral-50 rounded-full text-neutral-400\
    hover:bg-white\
    data-[state=active]:bg-white data-[state=active]:shadow data-[state=active]:text-black"
  const tabWrapperClasses =
    "bg-neutral-50 rounded-full w-full flex justify-between max-w-xl items-center my-8 min-w-[500px] select-none"

  return (
    <TabsList className={tabWrapperClasses}>
      <TabsTrigger className={tabClasses} value={"profile-details"}>
        Profile Details
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"care-circle"}>
        Care Circle
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"assignments"}>
        Assignments
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"permissions"}>
        Permissions
      </TabsTrigger>
    </TabsList>
  )
}

export default ClientOverviewTabs
