export const HRVInfo =
  "HealthKit calculates the Heart rate variability (HRV) by measuring the variation between individual heartbeats. While there are multiple ways of computing HRV, HealthKit uses SDNN heart rate variability, which uses the standard deviation of the inter-beat (RR) intervals between normal heartbeats (typically measured in milliseconds)."

export const TimeInDaylightInfo =
  "Time in Daylight is estimated by the Apple Watch using the ambient light sensor, GPS, and motion sensors."

export const ActiveCaloriesInfo =
  "HealthKit estimates Active Energy as the energy burned beyond Resting Energy. This includes activities such as walking, running and doing household chores."

export const ExerciseTimeInfo =
  "Exercise time is automatically estimated by Apple Watch using the accelerometer. During workout sessions, additional sensors, including the heart rate monitor and GPS, are utilized to enhance accuracy."
