interface Relationship {
  relationship_person: string
  relationship_type: string
  relationship_last_interaction: string
  relationship_sentiment: string
  relationship_quotes: string
}

interface RelationshipCardProps {
  relationship: Relationship
  activeRole: string | null
  onClick: (relationshipPerson: string) => void
}

const RelationshipCard: React.FC<RelationshipCardProps> = ({ relationship, onClick, activeRole }) => {
  const activeStyle = relationship.relationship_person === activeRole ? "shadow-xl" : ""
  return (
    <button
      className={`bg-white w-full p-3 rounded-lg text-left border hover:shadow-xl transition select-none duration-300 ease-in-out ${activeStyle}`}
      onClick={() => onClick(relationship.relationship_person)}
    >
      <div>
        <div>
          <div>{relationship.relationship_person}</div>
          <div className="text-xs flex flex-row">
            <div className="text-xs flex flex-row">
              <div className="flex flex-row items-center pr-4">
                <div className="text-neutral-300">type:</div>
                <div className="pl-1 text-sm text-neutral-500">{relationship.relationship_type}</div>
              </div>
              <div className="flex flex-row items-center pr-4">
                <div className="text-neutral-300">last interaction:</div>
                <div className="pl-1 text-sm text-neutral-500">{relationship.relationship_last_interaction}</div>
              </div>
              <div className="flex flex-row items-center">
                <div className="text-neutral-300">sentiment:</div>
                <div className="pl-1 text-sm text-neutral-500">{relationship.relationship_sentiment}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-base text-neutral-300 mt-2">"{relationship.relationship_quotes}"</div>
      </div>
    </button>
  )
}

export default RelationshipCard
