import { faCircleQuestion, faCopy, faEdit, faFileImport } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { marked } from "marked"
import { useEffect, useRef, useState } from "react"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"
import Modal from "@/components/Modals/Modal.tsx"

import { error, success } from "@utilities/logger.ts"

import BouncingDots from "./BouncingDots"
import IconInfoCard from "./IconInfoCard"
import useChatStore from "./stores/ChatStore"

// Import the new component

const ChatMessages: React.FC = () => {
  const messages = useChatStore((state) => state.messages)
  const loadingChatHistory = useChatStore((state) => state.loadingChatHistory)
  const setEditingMessage = useChatStore((state) => state.setEditingMessage)
  const removeMessage = useChatStore((state) => state.removeMessage)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [showTopOverlay, setShowTopOverlay] = useState(false)
  const removeCurrentMsg = false
  const [isInfoModalOpen, setInfoModalOpen] = useState(false)

  const handleOpenInfoModal = () => setInfoModalOpen(true)
  const handleCloseInfoModal = () => setInfoModalOpen(false)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [messages])

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleScroll = () => {
      if (container.scrollHeight > container.clientHeight && container.scrollTop > 0) {
        setShowTopOverlay(true)
      } else {
        setShowTopOverlay(false)
      }
    }

    container.addEventListener("scroll", handleScroll)
    handleScroll()

    return () => container.removeEventListener("scroll", handleScroll)
  }, [messages])

  const copyMarkdownText = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => success("Text copied as markdown"),
      (err) => error(err as Error),
    )
  }

  const copyMessageAsHtml = async (markdownText: string) => {
    try {
      const convertedHtml = await marked(markdownText)
      const fullHtml = `<!DOCTYPE html>
        <html>
          <head><meta charset="UTF-8"></head>
          <body>${convertedHtml}</body>
        </html>`

      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([fullHtml], { type: "text/html" }),
          "text/plain": new Blob([convertedHtml], { type: "text/plain" }),
        }),
      ])
      success("Text copied as HTML")
    } catch (err) {
      console.error("Copy failed", err)
      error(err as Error)
    }
  }

  const editMessage = (msg: ChatMessage) => {
    setEditingMessage(msg.text)
    if (removeCurrentMsg) {
      removeMessage(msg.id)
    }
  }

  if (loadingChatHistory && messages.length === 0) {
    return (
      <div className="flex flex-1 justify-center items-center h-full border-b">
        <LoadingSpinner size="2x" />
      </div>
    )
  }

  if (!messages || messages.length === 0) {
    return (
      <div className="flex flex-1 text-3xl font-bold justify-center items-center h-full border-b text-neutral-300">
        {"What can I help with?".split(" ").map((word, index) => (
          <span key={index} className="animate-pulse p-1" style={{ animationDelay: `${index * 0.2}s` }}>
            {word + " "}
          </span>
        ))}
      </div>
    )
  }

  return (
    <div className="relative h-full">
      {/* Scrollable messages container */}
      <div
        ref={containerRef}
        className="flex flex-col space-y-1 w-full h-full overflow-y-auto border-b relative           [&::-webkit-scrollbar]:w-1
          [&::-webkit-scrollbar-track]:bg-neutral-50
          [&::-webkit-scrollbar-thumb]:bg-neutral-200"
      >
        {messages.map((msg) => {
          // Determine alignment based on role
          const alignmentClass = msg.role === "user" ? "self-end" : "self-start"

          return (
            <div key={msg.id} className="flex flex-col group pr-2 ">
              {/* Message Bubble */}
              <div
                className={`p-4 px-6 rounded-2xl ${alignmentClass} ${
                  msg.role === "user" ? "bg-neutral-950 text-white" : "bg-neutral-50"
                }`}
              >
                {msg.role === "ai-assistant" && msg.text.trim() === "" ? (
                  <BouncingDots />
                ) : (
                  <Markdown
                    components={{
                      h1: ({ ...props }) => <h1 className="text-4xl font-bold mt-8 mb-4" {...props} />,
                      h2: ({ ...props }) => <h2 className="text-3xl font-semibold mt-6 mb-3" {...props} />,
                      h3: ({ ...props }) => <h3 className="text-2xl font-semibold mt-4 mb-2" {...props} />,
                      h4: ({ ...props }) => <h4 className="text-xl font-semibold mt-3 mb-1" {...props} />,
                      h5: ({ ...props }) => <h5 className="text-lg font-semibold mt-2 mb-1" {...props} />,
                      h6: ({ ...props }) => <h6 className="text-base font-semibold mt-2 mb-1" {...props} />,
                      p: ({ ...props }) => <p className="text-base" {...props} />,
                      a: ({ ...props }) => (
                        <a
                          className="text-white text-sm bg-neutral-500 rounded-xl px-2 hover:underline bg-opacity-50"
                          {...props}
                        />
                      ),
                      pre: ({ ...props }) => (
                        <pre
                          className="whitespace-pre-wrap my-2 bg-neutral-200 p-4 bg-opacity-25 rounded-lg break-words"
                          {...props}
                        />
                      ),
                      code: ({ ...props }) => (
                        <code className="bg-neutral-100 px-1 text-green-700 rounded" {...props} />
                      ),
                      span: ({ ...props }) => <span className="break-words" {...props} />,
                      li: ({ ...props }) => <li className="list-disc ml-6 my-2 text-base leading-relaxed" {...props} />,
                      ul: ({ ...props }) => (
                        <ul
                          className="list-disc ml-6 my-2 text-base leading-relaxed rounded-xl p-3hover:bg-white"
                          {...props}
                        />
                      ),
                      table: ({ ...props }) => (
                        <div className="overflow-x-auto my-4 x">
                          <table className="w-full text-sm text-left border-collapse" {...props} />
                        </div>
                      ),
                      thead: ({ ...props }) => (
                        <thead className="text-xs uppercase bg-neutral-200 bg-opacity-25" {...props} />
                      ),
                      tbody: ({ ...props }) => <tbody {...props} />,
                      tr: ({ ...props }) => (
                        <tr
                          className="border-b border-neutral-200 hover:bg-white transition ease-in-out hover:bg-opacity-50"
                          {...props}
                        />
                      ),
                      th: ({ ...props }) => <th className="px-6 py-3 border border-neutral-200" {...props} />,
                      td: ({ ...props }) => <td className="px-6 py-3 border border-neutral-200" {...props} />,
                    }}
                    remarkPlugins={[remarkGfm]}
                  >
                    {msg.text}
                  </Markdown>
                )}
              </div>
              {/* Action Icons */}
              <div
                className={`flex space-x-3 mt-1 ${alignmentClass} opacity-0 group-hover:opacity-100 transition-opacity duration-200`}
              >
                <button onClick={() => handleOpenInfoModal()} className="text-neutral-200 hover:text-neutral-500">
                  <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
                </button>
                <button
                  onClick={() => {
                    void copyMarkdownText(msg.text)
                  }}
                  className="text-neutral-300 hover:text-neutral-500"
                >
                  <FontAwesomeIcon icon={faFileImport} size="sm" />
                </button>
                <button
                  onClick={() => {
                    void copyMessageAsHtml(msg.text)
                  }}
                  className="text-neutral-300 hover:text-neutral-500"
                >
                  <FontAwesomeIcon icon={faCopy} size="sm" />
                </button>

                {msg.role === "user" && (
                  <button onClick={() => editMessage(msg)} className="text-neutral-300 hover:text-neutral-500">
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </button>
                )}
              </div>
            </div>
          )
        })}
      </div>

      {/* Conditionally render the white gradient overlay at the top */}
      {showTopOverlay && (
        <div className="absolute top-0 left-0 w-full h-6 pointer-events-none bg-gradient-to-b from-white to-transparent z-20" />
      )}

      {/* Chart Info */}
      <Modal isOpen={isInfoModalOpen} size="sm" title="Icon Guide" onClose={handleCloseInfoModal}>
        <div className="p-4 pt-0 mt-0 space-y-4">
          <IconInfoCard
            containerPadding="p-4"
            icon={faEdit}
            title="Edit Prompt"
            description="Copy the current prompt into the text input field."
          />
          <IconInfoCard
            icon={faFileImport}
            title="Copy Formatted Text AS Markdown"
            description={
              <>
                Best for markdown text editor
                <br />
                (Typora/Notion/Obsidian)
              </>
            }
          />
          <IconInfoCard
            icon={faCopy}
            title="Copy Formatted Text AS HTML"
            description={
              <>
                Best for paste into text editor
                <br />
                (GoogleDoc/Word/Pages)
              </>
            }
          />
        </div>
      </Modal>
    </div>
  )
}

export default ChatMessages
