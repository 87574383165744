import { ReactNode } from "react"
import { Link } from "react-router-dom"

interface IconButtonProps {
  onClick?: (...args: never[]) => void | Promise<void> | Promise<boolean>
  href?: string
  to?: string
  disabled?: boolean
  className?: string
  children: ReactNode
  color?: TailwindColorsWithShade
  textColor?: TailwindColorsWithShade
  tooltip?: string
  hasBorder?: boolean
  hidden?: boolean
}

const IconButton = ({
  onClick,
  href,
  to,
  disabled,
  className = "",
  color = "white",
  textColor = "neutral-500",
  hasBorder = true,
  children,
  tooltip,
  hidden = false,
}: IconButtonProps) => {
  const borderStyle = hasBorder ? "border" : ""
  let classes =
    className +
    " " +
    `text-${textColor} bg-${color} hover:shadow-lg transition focus:outline-none rounded-full h-10 px-3 text-center ${borderStyle} items-center justify-center flex`
  if (disabled) classes += " opacity-50 cursor-not-allowed"

  // Create the button/link element
  const buttonElement = (() => {
    if (to)
      return (
        <Link to={to} type="button" className={classes}>
          {children}
        </Link>
      )
    if (href)
      return (
        <a href={href} type="button" className={classes}>
          {children}
        </a>
      )
    return (
      <button onClick={onClick} type="button" className={classes} disabled={disabled}>
        {children}
      </button>
    )
  })()

  if (hidden) return null

  // If a tooltip is provided, wrap the element with a relative container
  if (tooltip) {
    return (
      <div className="relative group inline-block">
        {buttonElement}
        <div
          className="
            absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 
            group-hover:opacity-100 transition-opacity duration-300 pointer-events-none
          "
        >
          <div className="bg-black text-white rounded-full text-nowrap px-3 py-1 text-xs">{tooltip}</div>
        </div>
      </div>
    )
  }

  return buttonElement
}

export default IconButton
