import * as d3 from "d3"

class NumericalHistoryD3 {
  constructor(element, width, height, data) {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 20, right: 0, bottom: 30, left: 40 }
    this.svg = null

    this.createChart()
  }

  createChart() {
    // Remove any existing SVG
    d3.select(this.element).select("svg").remove()

    if (!this.data || !this.data.responses || this.data.responses.length === 0) {
      return
    }

    const svg = d3.select(this.element).append("svg").attr("width", this.width).attr("height", this.height)

    const chartGroup = svg.append("g").attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    // Parse dates using the given format.
    const parseDate = d3.timeParse("%Y-%m-%d")

    // Process responses
    const responses = this.data.responses.map((d) => ({
      date: parseDate(d.date),
      values: d.values, // might be empty
    }))

    // Get all unique dates from responses (even if there’s no value)
    const allDates = Array.from(new Set(responses.map((r) => r.date.getTime())))
      .map((t) => new Date(t))
      .sort((a, b) => a - b)

    // Flatten the responses for multiple values.
    // For a response with an empty array, nothing is added
    const flattenedData = responses.flatMap((response) =>
      response.values.map((value) => ({
        date: response.date,
        value: value === null || value === "" ? null : isNaN(+value) ? value : +value,
      })),
    )

    // Determine valid dates (those with at least one numeric value)
    const validDatesSet = new Set(
      flattenedData.filter((d) => d.value !== null && typeof d.value === "number").map((d) => d.date.getTime()),
    )
    const validDates = allDates.filter((date) => validDatesSet.has(date.getTime()))
    const missingDates = allDates.filter((date) => !validDatesSet.has(date.getTime()))

    // Set up y-domain:
    // If a choices array is valid, use it for the domain.
    // Otherwise, use default to [0,10 or maxValue]
    const nonNullValues = flattenedData
      .filter((d) => d.value !== null && typeof d.value === "number")
      .map((d) => d.value)

    let yMin, yMax
    if (this.data.choices && this.data.choices.length >= 2) {
      yMin = +this.data.choices[0]
      yMax = +this.data.choices[this.data.choices.length - 1]
      if (isNaN(yMin) || isNaN(yMax)) {
        yMin = 0
        yMax = nonNullValues.length > 0 ? d3.max(nonNullValues) : 10
      }
    } else {
      yMin = 0
      yMax = nonNullValues.length > 0 ? d3.max(nonNullValues) : 10
    }

    // Define scales
    const xScale = d3.scaleBand().domain(allDates).range([0, innerWidth]).padding(0.2)

    const yScale = d3.scaleLinear().domain([yMin, yMax]).range([innerHeight, 0]).nice()

    // Draw horizontal grid lines (y-axis)
    chartGroup
      .selectAll(".y-grid-line")
      .data(yScale.ticks())
      .enter()
      .append("line")
      .attr("class", "y-grid-line")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4")

    // Draw vertical grid lines for valid dates (lighter style)
    chartGroup
      .selectAll(".x-grid-line")
      .data(validDates)
      .enter()
      .append("line")
      .attr("class", "x-grid-line")
      .attr("x1", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("x2", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "#ccc")
      .attr("opacity", 0.4)
      .attr("stroke-dasharray", "3")

    // For dates with no numeric value, draw a vertical dashed line to indicate missing data
    chartGroup
      .selectAll(".missing-line")
      .data(missingDates)
      .enter()
      .append("line")
      .attr("class", "missing-line")
      .attr("x1", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("x2", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "#ccc")
      .attr("opacity", 0.4)
      .attr("stroke-dasharray", "3")

    // Configure x-axis ticks: when more than 20 dates, only show every other date
    let tickValues = allDates
    if (allDates.length > 20) {
      tickValues = allDates.filter((d, i) => i % 2 === 0)
    }

    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(tickValues)
      .tickFormat((d) => d3.timeFormat("%m/%d")(d))
      .tickSize(0)

    chartGroup
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("path, .tick line")
      .remove()

    chartGroup.selectAll(".tick text").style("font-size", "10px").attr("dy", "15px")

    // Configure y-axis
    const yAxis = d3.axisLeft(yScale).tickSize(0)
    const yAxisGroup = chartGroup.append("g").call(yAxis)
    yAxisGroup.selectAll("path, .tick line").remove()
    yAxisGroup.selectAll(".tick text").style("font-size", "10px")

    // Draw line connecting the dots.
    // The line generator now only uses numeric values
    const lineGenerator = d3
      .line()
      .defined((d) => d.value !== null && typeof d.value === "number")
      .x((d) => xScale(d.date) + xScale.bandwidth() / 2)
      .y((d) => yScale(d.value))
      .curve(d3.curveMonotoneX)

    chartGroup
      .append("path")
      .datum(flattenedData)
      .attr("class", "line")
      .attr("d", lineGenerator)
      .attr("fill", "none")
      .attr("stroke", "#000")
      .attr("stroke-width", 2)
      .style("opacity", 0.4)

    // Draw dots and value labels only for numeric data points
    const validData = flattenedData.filter((d) => d.value !== null && typeof d.value === "number")

    chartGroup
      .selectAll("circle")
      .data(validData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.value))
      .attr("r", 6)
      .attr("fill", "#000")

    chartGroup
      .selectAll(".dot-label")
      .data(validData)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.value) - 10)
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .text((d) => d.value)

    // --- Tooltip for displaying date ---
    // Create a hidden tooltip div
    const tooltip = d3
      .select(this.element)
      .append("div")
      .attr("class", "numerical-history-tooltip")
      .style("position", "absolute")
      .style("padding", "8px 12px")
      .style("background", "#000")
      .style("color", "#fff")
      .style("font-size", "12px")
      .style("border-radius", "12px")
      .style("pointer-events", "none")
      .style("opacity", 0)

    // Add a vertical dashed line to follow the tooltip
    const tooltipLine = chartGroup
      .append("line")
      .attr("class", "tooltip-line")
      .attr("stroke", "#000")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3")
      .style("opacity", 0)

    // Add overlay rectangle to handle tooltip events
    chartGroup
      .append("rect")
      .attr("class", "overlay")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", (event) => this.handleMouseMove(event, flattenedData, xScale, tooltip, tooltipLine, innerHeight))
      .on("mouseout", () => {
        tooltip.style("opacity", 0)
        tooltipLine.style("opacity", 0)
      })
  }

  // Handle mouse move event to display the date tooltip
  handleMouseMove(event, flattenedData, xScale, tooltip, tooltipLine, innerHeight) {
    // Hide any existing tooltip
    d3.selectAll(".numerical-history-tooltip").style("opacity", 0)

    const [xPos] = d3.pointer(event)
    // Get the unique dates from the xScale domain
    const dates = xScale.domain()
    const xPositions = dates.map((date) => xScale(date) + xScale.bandwidth() / 2)
    const index = xPositions.reduce(
      (prev, curr, currIndex) => (Math.abs(curr - xPos) < Math.abs(xPositions[prev] - xPos) ? currIndex : prev),
      0,
    )
    const selectedDate = dates[index]
    const formatDate = d3.timeFormat("%m/%d")
    const tooltipContent = `${formatDate(selectedDate)}`

    tooltip
      .html(tooltipContent)
      .style("left", `${event.pageX + 15}px`)
      .style("top", `${event.pageY - 20}px`)
      .style("opacity", 1)

    tooltipLine
      .attr("x1", xScale(selectedDate) + xScale.bandwidth() / 2)
      .attr("x2", xScale(selectedDate) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .style("opacity", 0.4)
  }
}

export default NumericalHistoryD3
