import SectionContainer from "../atom/SectionContainer"
import TranscriptList from "./TranscriptList.tsx"
import TranscriptListProvider from "./provider/TranscriptListProvider.tsx"

const TranscriptSection: React.FC = () => {
  return (
    <TranscriptListProvider>
      <SectionContainer title="Transcript History" description="View the past session analysis and transcript">
        <TranscriptList />
      </SectionContainer>
    </TranscriptListProvider>
  )
}

export default TranscriptSection
