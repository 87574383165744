import { create } from "zustand"

interface TranscriptMoodStore {
  transcriptMoods: TranscriptMood[]
  setTranscriptMoods: (moods: TranscriptMood[]) => void
}

const useTranscriptMoodStore = create<TranscriptMoodStore>((set) => ({
  transcriptMoods: [],
  setTranscriptMoods: (transcriptMoods) => set({ transcriptMoods }),
}))

export default useTranscriptMoodStore
