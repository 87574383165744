import ActivityStackGraph from "../graph/ActivityStackGraph"
import SleepAndStepsGraphTabs from "./SleepAndStepsGraphTabs"
import useActivityInsightStore from "./store/ActivityInsightStore"

const SleepAndStepsCard: React.FC = () => {
  const currentGraphData = useActivityInsightStore((state) => state.currentActivityResponse)
  if (!currentGraphData) return null

  return (
    <div className="w-full h-full flex flex-col items-center mt-3">
      <div className="text-xl w-full">Activity Heatmap</div>
      <ActivityStackGraph graphData={currentGraphData} />
      <SleepAndStepsGraphTabs />
    </div>
  )
}

export default SleepAndStepsCard
