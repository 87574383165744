import Card from "@/components/Cards/Card.tsx"

import CardioRangeSelector from "../activity/CardioRangeSelector"
import DailyCardioCard from "../activity/DailyCardioCard"
import SectionContainer from "../atom/SectionContainer"

const CardioSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <SectionContainer
      title="Cardio"
      description="The avg heart rate is the mean of available samples"
      headerControls={<CardioRangeSelector />}
    >
      <div className="flex gap-3 flex-col mt-3">
        <Card additionalClasses={cardStyle}>
          <DailyCardioCard />
        </Card>
      </div>
    </SectionContainer>
  )
}

export default CardioSection
