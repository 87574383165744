import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

// Import the relationship graph store instead of the transcript mood store
import useRelationshipGraphStore from "../store/RelationshipGraphStore"

const RelationshipGraphProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentRelationshipGraphData = useRelationshipGraphStore((state) => state.setCurrentRelationshipGraphData)
  const { recordingId, clientId } = useParams()

  const {
    data: relationshipGraphData,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["relationship-graph", recordingId, clientId],
    retry: false,
    enabled: !!recordingId,
    queryFn: async () => {
      const endpoint = `/llm/structured/generate/relationship/${clientId}/${recordingId}`
      const response = await api.get<RelationshipGraphResponse>(endpoint)
      if (response.status === 200) return response.data.data
      throw new Error("Fetching relationship graph failed")
    },
  })

  useEffect(() => {
    if (isSuccess && relationshipGraphData) {
      setCurrentRelationshipGraphData(relationshipGraphData)
    }
  }, [relationshipGraphData, isSuccess, setCurrentRelationshipGraphData])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError || !relationshipGraphData)
    return <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load relationship graph</div>

  return <>{children}</>
}

export default RelationshipGraphProvider
