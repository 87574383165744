import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useRelationshipMoodStore from "../store/RelationshipMoodStore"

const RelationshipMoodProvider = ({ children }: { children: ReactNode }) => {
  const setRelationshipMoods = useRelationshipMoodStore((state) => state.setRelationshipMoods)
  const currentActiveRelationshipRole = useRelationshipMoodStore((state) => state.currentActiveRelationshipRole)
  const { recordingId, clientId } = useParams()

  const {
    data: relationshipMoodData,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [
      "relationship-moods",
      currentActiveRelationshipRole,
      recordingId,
      currentActiveRelationshipRole,
      clientId,
    ],
    retry: false,
    enabled: !!recordingId && !!currentActiveRelationshipRole,
    queryFn: async () => {
      const endpoint = `/llm/structured/generate/relationship/mood/${currentActiveRelationshipRole}/${clientId}/${recordingId}`
      const response = await api.get<TranscriptMoodResponse>(endpoint)
      if (response.status === 200) return response.data.data
      throw new Error("Fetching relationship mood failed")
    },
  })

  useEffect(() => {
    if (isSuccess && relationshipMoodData) {
      setRelationshipMoods(relationshipMoodData)
    }
  }, [relationshipMoodData, isSuccess, setRelationshipMoods])

  if (isLoading)
    return (
      <div className="py-6 w-96 h-96 bg-white rounded-lg border flex-shrink-0">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError)
    return (
      <div className="py-6 w-96 h-96 bg-white rounded-lg border flex-shrink-0 flex items-center justify-center">
        <div className="text-center text-sm text-neutral-200">Could not load relationship mood</div>
      </div>
    )

  if (!relationshipMoodData)
    return (
      <div className="py-6 w-96 h-96 bg-white rounded-lg border flex-shrink-0 flex items-center justify-center">
        <div className="text-center text-sm text-neutral-200">Selection a relationship to start</div>
      </div>
    )

  return <>{children}</>
}

export default RelationshipMoodProvider
