import { create } from "zustand"

interface RelationshipMoodStore {
  currentActiveRelationshipRole: string | null
  setCurrentActiveRelationshipRole: (role: string) => void
  relationshipMoods: TranscriptMood[]
  setRelationshipMoods: (moods: TranscriptMood[]) => void
}

const useRelationshipMoodStore = create<RelationshipMoodStore>((set) => ({
  currentActiveRelationshipRole: null,
  setCurrentActiveRelationshipRole: (role) => set({ currentActiveRelationshipRole: role }),
  relationshipMoods: [],
  setRelationshipMoods: (relationshipMoods) => set({ relationshipMoods }),
}))

export default useRelationshipMoodStore
